/* Montserrat Font Face */
/* Montserrat Font */
/* Menlo Font */
/* Variables */
/* Colors */
/* Checker code Theme */
/* Mixins */
/* Floating shadow animation LOOP */
.unselectable,
.unselectable > .CodeMirror.cm-s-checker-code,
.unselectable > .CodeMirror.cm-s-checker-code > div > textarea,
.unselectable > .CodeMirror.cm-s-checker-code .CodeMirror-line {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Validation response animation */
@-webkit-keyframes enlarge {
  0% {
    -webkit-transform: scale(0.5); }
  100% {
    -webkit-transform: scale(1); } }

@-moz-keyframes enlarge {
  0% {
    -moz-transform: scale(0.5); }
  100% {
    -moz-transform: scale(1); } }

@-o-keyframes enlarge {
  0% {
    -o-transform: scale(0.5); }
  100% {
    -o-transform: scale(1); } }

@keyframes enlarge {
  0% {
    transform: scale(0.5); }
  100% {
    transform: scale(1); } }

/* floating laptop animation */
@-webkit-keyframes float-laptop {
  0% {
    -webkit-transform: translateY(0); }
  30% {
    -webkit-transform: translateY(-25px); }
  35% {
    -webkit-transform: translateY(-25px); }
  65% {
    -webkit-transform: translateY(-15px); }
  70% {
    -webkit-transform: translateY(-15px); }
  100% {
    -webkit-transform: translateY(-20px); } }

@-moz-keyframes float-laptop {
  0% {
    -moz-transform: translateY(0); }
  30% {
    -moz-transform: translateY(-25px); }
  35% {
    -moz-transform: translateY(-25px); }
  65% {
    -moz-transform: translateY(-15px); }
  70% {
    -moz-transform: translateY(-15px); }
  100% {
    -moz-transform: translateY(-20px); } }

@-o-keyframes float-laptop {
  0% {
    -o-transform: translateY(0); }
  30% {
    -o-transform: translateY(-25px); }
  35% {
    -o-transform: translateY(-25px); }
  65% {
    -o-transform: translateY(-15px); }
  70% {
    -o-transform: translateY(-15px); }
  100% {
    -o-transform: translateY(-20px); } }

@keyframes float-laptop {
  0% {
    transform: translateY(0); }
  30% {
    transform: translateY(-25px); }
  35% {
    transform: translateY(-25px); }
  65% {
    transform: translateY(-15px); }
  70% {
    transform: translateY(-15px); }
  100% {
    transform: translateY(-20px); } }

/* Floating shadow animation */
@-webkit-keyframes float-shadow {
  0% {
    opacity: 0.8; }
  30% {
    opacity: 0.2; }
  35% {
    opacity: 0.2; }
  65% {
    opacity: 0.3; }
  70% {
    opacity: 0.3; }
  100% {
    opacity: 0.2; } }

@-moz-keyframes float-shadow {
  0% {
    opacity: 0.8; }
  30% {
    opacity: 0.2; }
  35% {
    opacity: 0.2; }
  65% {
    opacity: 0.3; }
  70% {
    opacity: 0.3; }
  100% {
    opacity: 0.2; } }

@-o-keyframes float-shadow {
  0% {
    opacity: 0.8; }
  30% {
    opacity: 0.2; }
  35% {
    opacity: 0.2; }
  65% {
    opacity: 0.3; }
  70% {
    opacity: 0.3; }
  100% {
    opacity: 0.2; } }

@keyframes float-shadow {
  0% {
    opacity: 0.8; }
  30% {
    opacity: 0.2; }
  35% {
    opacity: 0.2; }
  65% {
    opacity: 0.3; }
  70% {
    opacity: 0.3; }
  100% {
    opacity: 0.2; } }

/* Floating laptop keyframes LOOP */
@-webkit-keyframes float-laptop-loop {
  0% {
    -webkit-transform: translateY(0); }
  30% {
    -webkit-transform: translateY(-25px); }
  35% {
    -webkit-transform: translateY(-15px); }
  65% {
    -webkit-transform: translateY(-20px); }
  70% {
    -webkit-transform: translateY(-15px); }
  100% {
    -webkit-transform: translateY(0px); } }

@-moz-keyframes float-laptop-loop {
  0% {
    -moz-transform: translateY(0); }
  30% {
    -moz-transform: translateY(-25px); }
  35% {
    -moz-transform: translateY(-15px); }
  65% {
    -moz-transform: translateY(-20px); }
  70% {
    -moz-transform: translateY(-15px); }
  100% {
    -moz-transform: translateY(0px); } }

@-o-keyframes float-laptop-loop {
  0% {
    -o-transform: translateY(0); }
  30% {
    -o-transform: translateY(-25px); }
  35% {
    -o-transform: translateY(-15px); }
  65% {
    -o-transform: translateY(-20px); }
  70% {
    -o-transform: translateY(-15px); }
  100% {
    -o-transform: translateY(0px); } }

@keyframes float-laptop-loop {
  0% {
    transform: translateY(0); }
  30% {
    transform: translateY(-25px); }
  35% {
    transform: translateY(-25px); }
  65% {
    transform: translateY(-20px); }
  70% {
    transform: translateY(-15px); }
  100% {
    transform: translateY(0px); } }

/* Floating shadow animation LOOP */
@-webkit-keyframes float-shadow-loop {
  0% {
    opacity: 0.8; }
  30% {
    opacity: 0.2; }
  35% {
    opacity: 0.3; }
  65% {
    opacity: 0.2; }
  70% {
    opacity: 0.3; }
  100% {
    opacity: 0.8; } }

@-moz-keyframes float-shadow-loop {
  0% {
    opacity: 0.8; }
  30% {
    opacity: 0.2; }
  35% {
    opacity: 0.3; }
  65% {
    opacity: 0.2; }
  70% {
    opacity: 0.3; }
  100% {
    opacity: 0.8; } }

@-o-keyframes float-shadow-loop {
  0% {
    opacity: 0.8; }
  30% {
    opacity: 0.2; }
  35% {
    opacity: 0.3; }
  65% {
    opacity: 0.2; }
  70% {
    opacity: 0.3; }
  100% {
    opacity: 0.8; } }

@keyframes float-shadow-loop {
  0% {
    opacity: 0.8; }
  30% {
    opacity: 0.2; }
  35% {
    opacity: 0.3; }
  65% {
    opacity: 0.2; }
  70% {
    opacity: 0.3; }
  100% {
    opacity: 0.8; } }

/* Spent trial animation */
@-webkit-keyframes spent-trial-num {
  0% {
    -webkit-transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(5);
    opacity: 0; } }

@-moz-keyframes spent-trial-num {
  0% {
    -moz-transform: scale(1);
    opacity: 1; }
  100% {
    -moz-transform: scale(5);
    opacity: 0; } }

@-o-keyframes spent-trial-num {
  0% {
    -o-transform: scale(1);
    opacity: 1; }
  100% {
    -o-transform: scale(5);
    opacity: 0; } }

@keyframes spent-trial-num {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(5);
    opacity: 0; } }

/* Spent trial bg fade animation */
@-webkit-keyframes spent-trial-bg {
  0% {
    background-color: #098CB3; }
  40% {
    background-color: #000000; }
  50% {
    background-color: #000000; }
  100% {
    background-color: #098CB3; } }

@-moz-keyframes spent-trial-bg {
  0% {
    background-color: #098CB3; }
  40% {
    background-color: #000000; }
  50% {
    background-color: #000000; }
  100% {
    background-color: #098CB3; } }

@-o-keyframes spent-trial-bg {
  0% {
    background-color: #098CB3; }
  40% {
    background-color: #000000; }
  50% {
    background-color: #000000; }
  100% {
    background-color: #098CB3; } }

@keyframes spent-trial-bg {
  0% {
    background-color: #098CB3; }
  40% {
    background-color: #000000; }
  50% {
    background-color: #000000; }
  100% {
    background-color: #098CB3; } }

/* delayed visibility for mobile subheader  */
@-webkit-keyframes delayed-visibility {
  0% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

@-moz-keyframes delayed-visibility {
  0% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

@-o-keyframes delayed-visibility {
  0% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

@keyframes delayed-visibility {
  0% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

/* Sprites */
.bg-sprites {
  background-image: url("../img/css-sprites-generic.png");
  background-repeat: no-repeat;
  display: block;
  image-rendering: -webkit-optimize-contrast;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  /* Chrome, Safari, Opera */
  transform: translateZ(0); }

.bg-arrow_left {
  background-position: -10px -230px;
  width: 19px;
  height: 17px; }

.bg-cloud {
  background-position: -115px -60px;
  width: 34px;
  height: 32px; }

.bg-download {
  background-position: -10px -137px;
  width: 34px;
  height: 28px; }

.bg-finaplo_insights {
  background-position: -10px -10px;
  width: 143px;
  height: 30px; }

.bg-github {
  background-position: -55px -185px;
  width: 42px;
  height: 12px; }

.bg-info_img {
  background-position: -233px -180px;
  width: 16px;
  height: 16px; }

.bg-invalid_img {
  background-position: -233px -10px;
  width: 17px;
  height: 27px; }

.bg-lightbulb {
  background-position: -173px -10px;
  width: 40px;
  height: 40px; }

.bg-logout {
  background-position: -233px -143px;
  width: 17px;
  height: 17px; }

.bg-magnifying_glass {
  background-position: -49px -230px;
  width: 11px;
  height: 11px; }

.bg-offer {
  background-position: -117px -185px;
  width: 27px;
  height: 9px; }

.bg-partnership {
  background-position: -173px -70px;
  width: 39px;
  height: 31px; }

.bg-paymentcomponents {
  background-position: -64px -137px;
  width: 41px;
  height: 21px; }

.bg-payment-components-logo-white {
  background-position: -125px -137px;
  width: 39px;
  height: 19px; }

.bg-sepa_logo {
  background-position: -164px -185px 22px;
  width: 6px;
  height: 0; }

.bg-swift_logo {
  background-position: -10px -185px;
  width: 25px;
  height: 25px; }

.bg-tick {
  background-position: -184px -137px;
  width: 25px;
  height: 18px; }

.bg-tree-collapsed {
  background-position: -80px -230px;
  width: 9px;
  height: 9px; }

.bg-tree-expanded {
  background-position: -109px -230px;
  width: 9px;
  height: 9px; }

.bg-valid_img {
  background-position: -233px -57px;
  width: 17px;
  height: 27px; }

.bg-verify {
  background-position: -10px -60px;
  width: 85px;
  height: 57px; }

/* AUTHENTICATION SPRITE */
.bg-auth {
  background-image: url("../img/css-sprites-auth.png");
  background-repeat: no-repeat;
  display: block; }
  .bg-auth.bg-laptop {
    background-position: -10px -10px;
    width: 235px;
    height: 211px; }
  .bg-auth.bg-sad_laptop {
    background-position: -265px -10px;
    width: 212px;
    height: 211px; }
  .bg-auth.bg-shadow {
    background-position: -222px -241px;
    width: 194px;
    height: 112px; }
  .bg-auth.bg-user_forgot {
    background-position: -497px -334px;
    width: 128px;
    height: 142px; }
  .bg-auth.bg-user_login {
    background-position: -497px -10px;
    width: 128px;
    height: 142px; }
  .bg-auth.bg-user_register {
    background-position: -10px -241px;
    width: 192px;
    height: 216px; }
  .bg-auth.bg-user_reset {
    background-position: -497px -172px;
    width: 128px;
    height: 142px; }

/* HOMEPAGE SPRITE */
.bg-hp {
  background-image: url("../img/css-sprites-hp.png");
  background-repeat: no-repeat; }

.bg-api-text_sdk {
  background-position: -126px -91px;
  width: 14px;
  height: 9px; }

.bg-code_api {
  background-position: -85px -10px;
  width: 55px;
  height: 38px; }

.bg-code_sdk {
  background-position: -160px -10px;
  width: 41px;
  height: 29px; }

.bg-cog_api {
  background-position: -69px -91px;
  width: 36px;
  height: 36px; }

.bg-cog_sdk {
  background-position: -160px -59px;
  width: 27px;
  height: 27px; }

.bg-cube_api {
  background-position: -10px -10px;
  width: 55px;
  height: 61px; }

.bg-cube_sdk {
  background-position: -10px -91px;
  width: 39px;
  height: 44px; }

.bg-network-to_sdk {
  background-position: -85px -68px;
  width: 30px;
  height: 3px; }

.bg-network-from_sdk {
  background-position: -95px -155px;
  width: 62px;
  height: 3px; }

.bg-network-from-to_api {
  background-position: -10px -155px;
  width: 65px;
  height: 3px; }

.bg-sdk-text_api {
  background-position: -10px -178px;
  width: 21px;
  height: 11px; }

.bg-sdk-text_sdk {
  background-position: -51px -178px;
  width: 15px;
  height: 8px; }

.bg-api-text_api {
  background-position: -160px -107px;
  width: 24px;
  height: 14px; }

/* HEADER MENU SPRITE */
.bg-mn {
  background-image: url("../img/css-sprites-mn.png");
  background-repeat: no-repeat;
  display: block; }

.bg-tm {
  background-position: -93px -5px;
  width: 47px;
  height: 37px; }

.bg-ai {
  background-position: -5px -5px;
  width: 38px;
  height: 26px; }

.bg-hub {
  background-position: -53px -5px;
  width: 30px;
  height: 36px; }

html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0 !important;
  overflow-x: hidden; }

html {
  height: 100%; }

body {
  position: relative;
  min-height: 100%; }
  body.modal-open {
    padding: 0 !important; }

.logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  justify-self: flex-start;
  flex-grow: 0;
  width: 320px;
  margin: 0 auto;
  padding: 10px 0 0 0; }
  .logo span {
    position: absolute;
    opacity: 0; }
  .logo img {
    height: auto;
    background-color: #033646;
    margin: 0 0 0 -60px; }
  .logo:hover a {
    text-decoration: none; }
  .logo .branding {
    margin: 5px 0 0 60px; }
  .logo .branding__finaplo {
    white-space: pre;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 25px;
    margin: 0;
    color: #ffffff; }
  .logo .branding__paymentcomponents {
    font: normal normal 400 10px/20px "Montserrat", sans-serif;
    margin: 3px 0 0 -1px;
    color: #48DCFF; }

.left__part {
  width: 20%;
  display: block;
  min-width: 215px; }

.middle__part {
  width: 52%; }
  .middle__part .question {
    height: 140px; }

.right__part {
  width: 28%; }

.main__app {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  min-height: calc(100vh - 100px - 240px); }

.main__home {
  position: relative;
  width: 100%; }

.main__register, .main__login, .main__reset, .main__purchase-request, .main__faq, .main__terms {
  background-color: #005872; }

.main__api, .main__online, .main__pricing, .main__financial-messages, .main__resources, .main__confirmation, .main__linkedin, .main__release-notes {
  background-color: #EFEFEF; }

.main__mt, .main__mx, .main__sepa, .main__translator, .main__target2, .main__cbpr {
  background-color: #EFEFEF; }

.main__charts {
  background-color: #1F1F1F; }
  .main__charts .chart__wrapper {
    width: 100%; }
  .main__charts figcaption {
    color: #ffffff;
    text-align: center;
    margin: 15px;
    font-size: 13px; }

.page__title {
  text-align: center;
  margin: 15px auto 28px auto;
  font: normal normal 400 24px/22px "Montserrat", sans-serif;
  color: #033646; }

.page__subtitle {
  font: normal normal 300 14px/20px "Montserrat", sans-serif;
  color: #003646;
  text-align: center; }
  .page__subtitle--pricing {
    width: 70%;
    max-width: 712px;
    margin: -12px auto 50px auto; }
  .page__subtitle--resources {
    margin: -10px auto 15px auto; }

.btn__close {
  width: 30px;
  height: 30px;
  top: -4px;
  right: -4px;
  position: absolute;
  padding: 0;
  color: #003646;
  font-weight: 500;
  font-size: 31px; }
  .btn__close:hover, .btn__close:focus, .btn__close:active {
    opacity: 0.76;
    color: #003646 !important;
    text-decoration: none !important; }

.btn__generic {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font: normal normal 400 16px/1 "Montserrat", sans-serif;
  color: #ffffff;
  background-color: #033646;
  border: 1px solid #033646;
  border-radius: 18px;
  height: 35px;
  padding: 8px 15px;
  transition: 0.25s; }
  .btn__generic:hover {
    text-decoration: none !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: #033646 !important;
    cursor: pointer; }
  .btn__generic:active, .btn__generic:focus, .btn__generic:active:focus {
    background-color: #033646 !important;
    border-color: #033646 !important;
    color: #ffffff !important;
    box-shadow: none !important; }

.btn__generic--reverse-clr {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font: normal normal 400 16px/1 "Montserrat", sans-serif;
  border-radius: 18px;
  height: 35px;
  padding: 8px 15px;
  transition: 0.25s;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #033646; }
  .btn__generic--reverse-clr:hover {
    text-decoration: none !important;
    color: #ffffff !important;
    border-color: #033646 !important;
    background-color: #033646;
    cursor: pointer; }
  .btn__generic--reverse-clr:active, .btn__generic--reverse-clr:focus, .btn__generic--reverse-clr:active:focus {
    background-color: #EFEFEF !important;
    border-color: #EFEFEF !important;
    color: #033646 !important;
    box-shadow: none !important; }

.btn__generic--blue-hover {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font: normal normal 400 16px/1 "Montserrat", sans-serif;
  color: #ffffff;
  background-color: #033646;
  border: 1px solid #033646;
  border-radius: 18px;
  height: 35px;
  padding: 8px 15px;
  transition: 0.25s; }
  .btn__generic--blue-hover:hover {
    text-decoration: none !important;
    background-color: #4FB9D3 !important;
    border-color: #4FB9D3 !important;
    color: #033646 !important;
    cursor: pointer; }
  .btn__generic--blue-hover:active, .btn__generic--blue-hover:focus, .btn__generic--blue-hover:active:focus {
    background-color: #8cd1e2 !important;
    border-color: #8cd1e2 !important;
    color: #033646 !important; }

.question__container {
  background-color: #EFEFEF; }

.tltp--wrapper {
  cursor: pointer; }

.terms {
  padding: 40px 60px 40px 20px;
  background-color: #ffffff;
  margin: 30px auto 0 !important;
  height: 100%;
  flex: 0 0 calc(100% - 38vw) !important;
  max-width: 1160px;
  position: relative; }
  .terms article {
    text-align: justify; }
  .terms .intro,
  .terms .outro {
    padding: 0 0 0 40px; }
    .terms .intro p,
    .terms .outro p {
      white-space: pre-wrap; }
  .terms > article > ol > li:first-child p {
    white-space: pre-wrap; }
  .terms > article > ol > li b {
    font-weight: 600 !important; }
  .terms > article > ol > li:before {
    font-weight: 600; }
  .terms > article > ol > li > ol {
    padding-inline-start: 0 !important; }
    .terms > article > ol > li > ol > li:before {
      content: counters(item, ".") " ";
      font-weight: 500; }
    .terms > article > ol > li > ol > li:last-child {
      margin-bottom: 20px; }
  .terms ol {
    counter-reset: item; }
  .terms li {
    display: block; }
  .terms li:before {
    content: counters(item, ".") ". ";
    counter-increment: item; }

.question {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .question__btn {
    width: 146px;
    height: 28px;
    border: 1px solid #033646;
    border-radius: 14px;
    color: #033646;
    transition: 0.25s;
    margin: auto;
    font: normal normal 400 12px/1.2 "Montserrat", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .question__btn:hover {
      background-color: #ffffff;
      color: #033646;
      text-decoration: none; }

.action__btn {
  transition: 0.25s;
  font: normal normal 400 12px/18px "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #3D8394;
  border: 1px solid #3D8394;
  border-radius: 13px;
  height: 28px;
  padding: 0 13px;
  color: #ffffff; }
  .action__btn:hover:not(:disabled) {
    text-decoration: none !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: #033646 !important;
    cursor: pointer; }
  .action__btn:active, .action__btn:focus, .action__btn:active:focus {
    background-color: #3D8394 !important;
    border-color: #3D8394 !important;
    color: #033646 !important;
    box-shadow: none !important; }
  .action__btn:disabled {
    color: #9B9B9B !important;
    background-color: rgba(255, 255, 255, 0.3);
    border-color: #9B9B9B !important;
    cursor: not-allowed !important;
    box-shadow: none !important; }

.finaplo__text > span:first-child {
  font-weight: 800; }

.finaplo__text > span:nth-child(2) {
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-left: 0.5px; }

.prompt__block {
  color: #ffffff;
  text-align: center;
  background-color: #3D8394; }
  .prompt__block article {
    margin: 0 auto;
    padding: 39px 20px; }
  .prompt__block p {
    font: normal normal 300 13px/20px "Montserrat", sans-serif; }
  .prompt__block h4 {
    font: normal normal 400 28px/38px "Montserrat", sans-serif;
    margin: 20px 0 0 0; }
  .prompt__block a {
    width: auto;
    height: 34px;
    margin: 20px 0 2px 0;
    padding: 0 18px;
    border-radius: 18px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font: normal normal 400 14px/1 "Montserrat", sans-serif;
    -webkit-transition: 0.25s;
    -moz-transition: 0.25s;
    -o-transition: 0.25s;
    -ms-transition: 0.25s;
    transition: 0.25s; }
    .prompt__block a:hover {
      text-decoration: none;
      background-color: #ffffff;
      color: #033646 !important; }
    .prompt__block a:active {
      background-color: #ffffff;
      color: #033646 !important; }

.offer__hdr-img {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }

.error__anim {
  position: relative;
  width: 212px;
  margin: 35px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center; }
  .error__anim--image--laptop {
    -webkit-animation: float-laptop-loop 3s ease infinite;
    -moz-animation: float-laptop-loop 3s ease infinite;
    -o-animation: float-laptop-loop 3s ease infinite;
    animation: float-laptop-loop 3s ease infinite;
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    -ms-transform: translateY(-20px); }
  .error__anim--image--shadow {
    -webkit-animation: float-shadow-loop 3s ease-in infinite;
    -moz-animation: float-shadow-loop 3s ease-in infinite;
    -o-animation: float-shadow-loop 3s ease-in infinite;
    animation: float-shadow-loop 3s ease-in infinite;
    position: absolute;
    bottom: 0;
    right: 19px;
    opacity: 0.2; }

.error-page {
  width: 100%;
  background-color: #DCE6E8;
  padding: 35px 20px;
  text-align: center; }
  .error-page article {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .error-page article h4 {
      color: #003646; }
    .error-page article p {
      font: normal normal 500 18px/1.3 "Montserrat", sans-serif;
      color: #033646; }

.crm {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.thank-you {
  width: 100vw; }
  .thank-you article {
    margin: calc(30px + 5vw) auto;
    width: calc(100% - 40px); }
  .thank-you p {
    font: normal normal 400 26px/1.3 "Montserrat", sans-serif;
    color: #033646;
    margin-top: 30px;
    text-align: center; }

.spinner.loading {
  padding: 50px;
  position: relative;
  text-align: center; }

.spinner.loading:before {
  content: "";
  height: 45px;
  width: 45px;
  margin: -15px auto auto -15px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-width: 8px;
  border-style: solid;
  border-color: #3D8394 #CCDCE0 #CCDCE0;
  border-radius: 100%;
  animation: spinner-rotation .5s infinite linear; }

.aplonhub {
  max-width: 1200px;
  margin: 15px auto 85px auto; }
  .aplonhub h4 {
    color: #166C81;
    text-align: center;
    max-width: 1050px;
    margin: 20px auto 48px auto;
    font: normal normal 400 38px/48px "Montserrat", sans-serif; }
  .aplonhub__container {
    background-image: linear-gradient(-158deg, #DD1259 2%, #A65888 12%, #7c8eac 30%, #5998a6 45%, #60B1C4 77%);
    padding: 40px 20px 25px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .aplonhub__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin: 22px 0 22px 0; }
  .aplonhub__showcase {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 50%;
    margin: 0 0 0 auto; }
    .aplonhub__showcase--logo {
      width: 213px;
      height: 54px; }
    .aplonhub__showcase--screenshot {
      border-radius: 12px;
      -webkit-box-shadow: 0 6px 20px rgba(0, 0, 0, 0.42) !important;
      -moz-box-shadow: 0 6px 20px rgba(0, 0, 0, 0.42) !important;
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.42) !important;
      margin: 25px 30px; }
    .aplonhub__showcase--link-to {
      margin-top: 27px; }
      .aplonhub__showcase--link-to i {
        position: relative;
        width: 22px;
        height: 22px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: #005872;
        margin: 0 8px 0 -8px; }
        .aplonhub__showcase--link-to i:after {
          position: absolute;
          left: 8px;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 8.7px;
          border-color: transparent transparent transparent #ffffff; }
  .aplonhub__features {
    max-width: calc(50% - 55px);
    margin: 5px auto 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
    .aplonhub__features ul {
      list-style-type: none;
      list-style-position: inside;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;
      list-style-position: outside; }
      .aplonhub__features ul li {
        display: flex;
        position: relative;
        color: #ffffff;
        font: normal normal 300 20px/28px "Montserrat", sans-serif; }
        .aplonhub__features ul li i {
          position: absolute;
          left: 0;
          top: 5px; }
        .aplonhub__features ul li span {
          margin: 0 0 22px 38px; }

@media screen and (max-width: 970px) {
  .page__subtitle {
    width: calc(100% - 30px) !important; }
  .terms {
    padding: 40px 20px !important;
    flex-basis: 100% !important; }
    .terms .intro,
    .terms .outro {
      padding: 0 !important; }
    .terms .outro p {
      text-align: left !important; }
    .terms article ol {
      padding-inline-start: 0 !important; }
  .logo .branding__paymentcomponents {
    margin: 0 0 0 -15px !important; } }

@media only screen and (max-width: 1095px) {
  .aplonhub {
    max-width: 100%;
    margin: 0 auto 70px auto; }
    .aplonhub h4 {
      font-size: 28px;
      line-height: 1.2;
      width: calc(100% - 40px);
      margin: 20px auto 45px auto; }
    .aplonhub__container {
      width: calc(100% - 20px);
      margin: 0 auto;
      padding: 20px 20px 40px; }
    .aplonhub__content {
      flex-direction: column; }
    .aplonhub__showcase {
      margin-right: auto; }
      .aplonhub__showcase--screenshot {
        max-width: calc(100vw - 70px);
        height: auto;
        margin: 15px 30px; }
      .aplonhub__showcase--link-to {
        white-space: nowrap;
        margin-top: 15px; }
    .aplonhub__features {
      max-width: calc(100% - 40px);
      margin: 5px auto 0 auto; } }

header {
  height: 100px;
  width: 100%;
  background-color: #033646;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }
  header nav {
    width: calc(100% - 18vw);
    height: inherit;
    max-width: calc(100% - 36vw);
    margin: 0 auto 0 0;
    flex-grow: 1; }
    @media only screen and (max-width: 1422px) and (min-width: 971px) {
      header nav {
        margin: 0 auto 0 66px; } }
    header nav ul {
      width: inherit;
      height: inherit;
      list-style: none;
      margin: 0 auto;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      header nav ul li {
        white-space: nowrap;
        height: 50px;
        margin: 20px 0 0 0; }
        header nav ul li > a {
          width: inherit;
          padding: 6px 12px;
          border-radius: 18px;
          background-color: transparent;
          display: inline-block;
          -webkit-transition: 0.45s;
          -moz-transition: 0.45s;
          -o-transition: 0.45s;
          -ms-transition: 0.45s;
          transition: 0.45s;
          color: #ffffff;
          font: normal normal 400 14px/18px "Montserrat", sans-serif;
          height: 30px; }
          header nav ul li > a > a {
            color: #ffffff;
            font: normal normal 400 14px/18px "Montserrat", sans-serif;
            height: 30px;
            text-decoration: none !important; }
          header nav ul li > a.nav__active,
          header nav ul li > a.nav__active > a {
            color: #48DCFF;
            text-decoration: none !important; }
        header nav ul li .nav__with-sub--title {
          width: inherit;
          padding: 6px 12px;
          border-radius: 18px;
          background-color: transparent;
          display: inline-block;
          -webkit-transition: 0.45s;
          -moz-transition: 0.45s;
          -o-transition: 0.45s;
          -ms-transition: 0.45s;
          transition: 0.45s;
          color: #ffffff;
          font: normal normal 400 14px/18px "Montserrat", sans-serif;
          height: 30px; }
          header nav ul li .nav__with-sub--title > a {
            color: #ffffff;
            font: normal normal 400 14px/18px "Montserrat", sans-serif;
            height: 30px;
            text-decoration: none !important; }
          header nav ul li .nav__with-sub--title.nav__active,
          header nav ul li .nav__with-sub--title.nav__active > a {
            color: #48DCFF;
            text-decoration: none !important; }
          header nav ul li .nav__with-sub--title__symbol {
            display: none; }
        header nav ul li .nav__with-sub--menu {
          display: block;
          max-height: 0;
          overflow: hidden;
          -webkit-box-shadow: -5px 5px 0 rgba(0, 0, 0, 0.42) !important;
          -moz-box-shadow: -5px 5px 0 rgba(0, 0, 0, 0.42) !important;
          box-shadow: -5px 5px 0 rgba(0, 0, 0, 0.42) !important;
          border-right: 1px solid rgba(0, 0, 0, 0.24);
          -webkit-transition: max-height 0.25s ease-in-out;
          -moz-transition: max-height 0.25s ease-in-out;
          -o-transition: max-height 0.25s ease-in-out;
          -ms-transition: max-height 0.25s ease-in-out;
          transition: max-height 0.25s ease-in-out; }
        @media only screen and (min-width: 971px) {
          header nav ul li:hover a,
          header nav ul li:hover .nav__with-sub--title {
            text-decoration: none;
            color: #48DCFF;
            cursor: pointer; }
          header nav ul li:hover > a:not(.nav__with-sub) {
            background-color: rgba(244, 253, 255, 0.1); } }
        @media only screen and (min-width: 971px) {
          header nav ul li.open-submenu__pc .nav__with-sub--menu.products {
            max-height: 391px;
            width: 581px; }
          header nav ul li.open-submenu__pc .nav__with-sub--menu.financialMessages {
            max-height: 346px; } }
        header nav ul li.free-trial__btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 27px;
          padding: 0 8px;
          margin: -5px 0 0 0;
          border-radius: 13px;
          background-color: #ffffff;
          transition: all 0.25s ease-in; }
          header nav ul li.free-trial__btn:hover {
            background-color: #27D7FF; }
          @media only screen and (max-width: 970px) {
            header nav ul li.free-trial__btn {
              margin: 15px 0 0 0; } }
          header nav ul li.free-trial__btn a {
            font: normal normal 500 13px/1.2 "Montserrat", sans-serif;
            color: #033545 !important;
            text-decoration: none;
            padding-top: 8px; }
        header nav ul li .nav__with-sub {
          position: relative; }
          header nav ul li .nav__with-sub--menu {
            position: absolute;
            z-index: 4;
            padding: 0;
            margin: 1px 0 0 0; }
            header nav ul li .nav__with-sub--menu article p {
              margin: 0; }
            header nav ul li .nav__with-sub--menu article.submenu__article--products {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start; }
              header nav ul li .nav__with-sub--menu article.submenu__article--products__finaplo {
                padding: 20px 20px 10px 25px; }
              header nav ul li .nav__with-sub--menu article.submenu__article--products__finaploai {
                padding: 15px 20px 20px 25px; }
              header nav ul li .nav__with-sub--menu article.submenu__article--products__aplonhub {
                padding: 18px 20px 20px 25px; }
              header nav ul li .nav__with-sub--menu article.submenu__article--products .product__tm {
                width: 86px;
                height: 86px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 5px 24px 0 0; }
                header nav ul li .nav__with-sub--menu article.submenu__article--products .product__tm--finaplo {
                  background-color: #083E50; }
                header nav ul li .nav__with-sub--menu article.submenu__article--products .product__tm--finaplo-ai {
                  background-color: #011C29; }
                header nav ul li .nav__with-sub--menu article.submenu__article--products .product__tm--aplonhub {
                  background-color: #0B6377; }
            header nav ul li .nav__with-sub--menu article.submenu__article--financialMessages {
              padding: 10px 15px; }
            header nav ul li .nav__with-sub--menu article .submenu__subtitle .finaplo__logo--img {
              margin: 0 0 13px 0; }
            header nav ul li .nav__with-sub--menu article .submenu__subtitle .aplonhub__logo--img {
              margin: 1px 0 6px 1px; }
            header nav ul li .nav__with-sub--menu article .submenu__content.products {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
              white-space: normal;
              margin-top: 8px; }
              header nav ul li .nav__with-sub--menu article .submenu__content.products > a {
                margin-right: 15px; }
                header nav ul li .nav__with-sub--menu article .submenu__content.products > a.sdk {
                  width: 122px; }
                header nav ul li .nav__with-sub--menu article .submenu__content.products > a.online {
                  width: 135px; }
                header nav ul li .nav__with-sub--menu article .submenu__content.products > a.api {
                  width: 128px; }
                header nav ul li .nav__with-sub--menu article .submenu__content.products > a.ai {
                  width: 242px; }
                header nav ul li .nav__with-sub--menu article .submenu__content.products > a.hub {
                  width: 237px;
                  margin-bottom: 8px; }
                header nav ul li .nav__with-sub--menu article .submenu__content.products > a h6 {
                  margin-bottom: 2px; }
                header nav ul li .nav__with-sub--menu article .submenu__content.products > a p {
                  font: normal normal 400 11px/14px "Montserrat", sans-serif;
                  margin: 0; }
            header nav ul li .nav__with-sub--menu article .submenu__content.financialMessages > a > h6 {
              line-height: 20px; }
            header nav ul li .nav__with-sub--menu article .submenu__content > a {
              color: #ffffff; }
              header nav ul li .nav__with-sub--menu article .submenu__content > a:hover {
                text-decoration: none;
                color: #c8f4ff; }
              header nav ul li .nav__with-sub--menu article .submenu__content > a > h6 {
                font: normal normal 600 12px/1.2 "Montserrat", sans-serif; }
            header nav ul li .nav__with-sub--menu article:nth-child(odd) {
              background-color: #307688; }
            header nav ul li .nav__with-sub--menu article:nth-child(even) {
              background-color: #166C81; }
              header nav ul li .nav__with-sub--menu article:nth-child(even) .submenu__subtitle {
                margin-top: 8px; }

.subheader {
  height: 100px;
  width: 100%;
  top: 0;
  z-index: 2;
  background-image: linear-gradient(45deg, #49AACC, #49AACC 5%, #3D8394 50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch; }
  .subheader__content {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
    height: inherit;
    width: inherit; }
  .subheader.left__part {
    margin: 0 auto; }
  .subheader hgroup {
    width: 320px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch; }
    .subheader hgroup h1 {
      font: normal normal 500 20px/18px "Montserrat", sans-serif;
      color: #ffffff;
      white-space: nowrap;
      text-align: left;
      padding: 0 0 0 12px;
      margin: 3px 0 0 46px;
      width: 207px; }
    .subheader hgroup h2 {
      font: normal normal 300 13px/20px "Montserrat", sans-serif;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      color: #ffffff;
      white-space: nowrap;
      text-align: left;
      padding: 0 0 0 12px;
      margin: 3px 0 0 46px;
      width: 207px; }
  .subheader__auth {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 70%;
    max-width: 300px; }
    .subheader__auth--wrapper__unauthed {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
    .subheader__auth--wrapper__authed {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center; }
    .subheader__auth button {
      border: 0 !important;
      outline: 0 !important; }
      .subheader__auth button:hover {
        text-decoration: underline;
        text-decoration-color: #003646; }
    .subheader__auth--logout {
      background-color: #60B1C4;
      width: 45px; }
    .subheader__auth button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100px; }
    .subheader__auth--link {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 100px;
      font: normal normal 500 14px/20px "Montserrat", sans-serif;
      text-decoration: none !important; }
      .subheader__auth--link > span {
        color: #ffffff; }
      .subheader__auth--link__login {
        background-color: transparent; }
        .subheader__auth--link__login:hover > span {
          color: #48DCFF; }
      .subheader__auth--link__signup {
        margin-right: -25px; }
        .subheader__auth--link__signup > span {
          -webkit-transition: 0.25s;
          -moz-transition: 0.25s;
          -o-transition: 0.25s;
          -ms-transition: 0.25s;
          transition: 0.25s;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #27D7FF;
          width: 76px;
          height: 27px;
          border-radius: 13px; }
        .subheader__auth--link__signup:hover > span {
          color: #033646;
          background-color: #ffffff; }
  .subheader__profile {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    height: 100px;
    margin-right: -15px; }
    .subheader__profile--subscription {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      height: inherit;
      padding: 12px 25px 12px 0; }
    .subheader__profile--email {
      font: normal normal 400 14px/1 "Montserrat", sans-serif;
      color: #ffffff;
      margin-top: 8px; }
    .subheader__profile--plan {
      width: auto;
      height: 20px;
      padding: 2px 12px;
      text-transform: uppercase;
      display: inline-block;
      vertical-align: middle;
      font: normal normal 600 12px/18px "Montserrat", sans-serif;
      color: #ffffff;
      border-radius: 20px;
      white-space: nowrap; }
      .subheader__profile--plan__active {
        background-color: #005872; }
      .subheader__profile--plan__inactive {
        background-color: #DD1259; }
    .subheader__profile--view-plans {
      color: #27D7FF;
      font: normal normal 400 12px/1 "Montserrat", sans-serif;
      text-decoration: underline;
      margin-top: auto; }
      .subheader__profile--view-plans:hover {
        color: #74e5ff !important; }
    .subheader__profile--trials {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: auto;
      color: #ffffff;
      padding: 0 20px;
      height: inherit;
      position: relative; }
      .subheader__profile--trials--animate {
        -webkit-animation: spent-trial-bg 1s ease-out 1;
        -moz-animation: spent-trial-bg 1s ease-out 1;
        -o-animation: spent-trial-bg 1s ease-out 1;
        animation: spent-trial-bg 1s ease-out 1; }
      .subheader__profile--trials__container {
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .subheader__profile--trials__container--active {
          background-color: #003646; }
        .subheader__profile--trials__container--inactive {
          background-color: #DD1259; }
      .subheader__profile--trials__counter {
        font: normal normal 600 22px/1 "Montserrat", sans-serif;
        margin-top: 6px;
        height: 22px; }
        .subheader__profile--trials__counter__copy {
          position: absolute;
          margin-top: 0; }
          .subheader__profile--trials__counter__copy--animate {
            -webkit-animation: spent-trial-num 0.8s ease-out 1;
            -moz-animation: spent-trial-num 0.8s ease-out 1;
            -o-animation: spent-trial-num 0.8s ease-out 1;
            animation: spent-trial-num 0.8s ease-out 1;
            transform-origin: top left; }
      .subheader__profile--trials__remaining {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font: normal normal 600 12px/12px "Montserrat", sans-serif; }
      .subheader__profile--trials__expire {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 2px;
        white-space: nowrap; }
        .subheader__profile--trials__expire--label {
          font: normal normal 600 12px/20px "Montserrat", sans-serif; }
        .subheader__profile--trials__expire--countdown {
          font: normal normal 400 12px/20px "Montserrat", sans-serif;
          margin-left: 4px; }

.burger-btn__wrapper {
  display: none; }

@media only screen and (max-width: 970px) {
  header {
    height: auto;
    min-height: 85px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    header .left__part {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 85px; }
    header h2 {
      font: normal normal 400 7px/13px "Montserrat", sans-serif; }
    header .logo {
      align-items: center;
      width: auto;
      height: 100%;
      padding: 5px 0 0 5px; }
      header .logo img {
        width: 290px;
        margin: 10px 0 0 -60px; }
    header nav {
      position: absolute;
      top: 85px;
      overflow: hidden;
      height: 295px;
      max-height: 0;
      z-index: 10;
      background-color: #003646;
      width: 100%;
      max-width: initial;
      margin: 0 0 0 -15px;
      -webkit-transition: max-height 0.25s ease-in-out;
      -moz-transition: max-height 0.25s ease-in-out;
      -o-transition: max-height 0.25s ease-in-out;
      -ms-transition: max-height 0.25s ease-in-out;
      transition: max-height 0.25s ease-in-out; }
      header nav:not(.extended-height) {
        height: 295px; }
      header nav.extended-height {
        height: calc(295px + 50px); }
      header nav:not(.nav__open) ul {
        visibility: hidden !important; }
      header nav.nav__open {
        -webkit-transition: max-height 0.25s ease-in-out;
        -moz-transition: max-height 0.25s ease-in-out;
        -o-transition: max-height 0.25s ease-in-out;
        -ms-transition: max-height 0.25s ease-in-out;
        transition: max-height 0.25s ease-in-out;
        overflow: visible; }
        header nav.nav__open:not(.extended-height) {
          max-height: 295px; }
          header nav.nav__open:not(.extended-height) ul {
            height: 255px; }
        header nav.nav__open.extended-height {
          max-height: calc(295px + 50px); }
          header nav.nav__open.extended-height ul {
            height: calc(295px + 50px - 40px); }
      header nav ul {
        visibility: hidden;
        -webkit-animation: delayed-visibility 0.25s ease-in-out 1s 1;
        -moz-animation: delayed-visibility 0.25s ease-in-out 1s 1;
        -o-animation: delayed-visibility 0.25s ease-in-out 1s 1;
        animation: delayed-visibility 0.25s ease-in-out 1s 1;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 0 0 10px; }
        header nav ul.is-visible {
          visibility: visible; }
        header nav ul li:not(.free-trial__btn) {
          width: calc(100% - 10px); }
        header nav ul li.free-trial__btn a {
          padding: 6px 12px !important; }
        header nav ul li a,
        header nav ul li .nav__with-sub--title {
          font-size: 16px !important; }
        header nav ul li.open-submenu__mob .nav__with-sub--menu {
          -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.42) !important;
          -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.42) !important;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.42) !important; }
          header nav ul li.open-submenu__mob .nav__with-sub--menu.products {
            max-height: 634px !important; }
          header nav ul li.open-submenu__mob .nav__with-sub--menu.financialMessages {
            max-height: 460px !important; }
        header nav ul .nav__with-sub--title__symbol {
          display: inline-block !important;
          width: 14px;
          height: 14px;
          position: absolute;
          right: 10px;
          margin-top: 3px; }
          header nav ul .nav__with-sub--title__symbol.closed .vertical {
            transition: all 0.5s ease-in-out;
            transform: rotate(-90deg); }
          header nav ul .nav__with-sub--title__symbol.closed .horizontal {
            transition: all 0.5s ease-in-out;
            transform: rotate(-90deg);
            opacity: 1; }
          header nav ul .nav__with-sub--title__symbol.opened {
            opacity: 1; }
            header nav ul .nav__with-sub--title__symbol.opened .vertical {
              transition: all 0.5s ease-in-out;
              transform: rotate(90deg); }
            header nav ul .nav__with-sub--title__symbol.opened .horizontal {
              transition: all 0.5s ease-in-out;
              transform: rotate(90deg);
              opacity: 0; }
          header nav ul .nav__with-sub--title__symbol .horizontal {
            position: absolute;
            background-color: #ffffff;
            width: 18px;
            height: 2px;
            left: 50%;
            margin-left: -9px;
            top: 50%;
            margin-top: -1px; }
          header nav ul .nav__with-sub--title__symbol .vertical {
            position: absolute;
            background-color: #ffffff;
            width: 2px;
            height: 18px;
            left: 50%;
            margin-left: -1px;
            top: 50%;
            margin-top: -9px; }
        header nav ul .nav__with-sub--title.nav__active .nav__with-sub--title__symbol > span {
          background-color: #48DCFF !important; }
        header nav ul .nav__with-sub--menu {
          margin: 6px 0 0px -10px !important;
          width: 100vw; }
          header nav ul .nav__with-sub--menu .submenu__article--products {
            padding: 20px !important; }
            header nav ul .nav__with-sub--menu .submenu__article--products > p:first-child {
              display: none !important; }
          header nav ul .nav__with-sub--menu .submenu__content > a h6,
          header nav ul .nav__with-sub--menu .submenu__content > a p {
            font-size: 16px !important;
            line-height: 1.2 !important; }
          header nav ul .nav__with-sub--menu .submenu__content.products {
            flex-direction: column !important;
            white-space: normal !important; }
            header nav ul .nav__with-sub--menu .submenu__content.products > a {
              width: 100% !important; }
              header nav ul .nav__with-sub--menu .submenu__content.products > a:not(:last-child) {
                margin: 0 0 28px 0 !important; }
              header nav ul .nav__with-sub--menu .submenu__content.products > a:last-child {
                margin: 0 0 10px 0 !important; }
          header nav ul .nav__with-sub--menu .submenu__content.financialMessages > a:not(:last-child) > h6 {
            margin: 0 0 18px 0 !important; }
    header .burger-btn {
      width: 28px;
      height: 21px;
      position: absolute;
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      -ms-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      cursor: pointer;
      margin: auto;
      z-index: 9;
      display: block; }
      header .burger-btn__wrapper {
        display: block;
        position: relative;
        width: 65px;
        height: 85px;
        margin-left: -15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; }
      header .burger-btn span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #ffffff;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        -ms-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out; }
      header .burger-btn span:nth-child(1) {
        top: 0; }
      header .burger-btn span:nth-child(2),
      header .burger-btn span:nth-child(3) {
        top: 8px; }
      header .burger-btn span:nth-child(4) {
        top: 16px; }
      header .burger-btn.open span:nth-child(1) {
        top: 8px;
        width: 0;
        left: 50%; }
      header .burger-btn.open span:nth-child(2) {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg); }
      header .burger-btn.open span:nth-child(3) {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg); }
      header .burger-btn.open span:nth-child(4) {
        top: 8px;
        width: 0;
        left: 50%; }
  .subheader__content {
    padding: 20px 15px; }
  .subheader hgroup {
    width: calc(100vw - 40px); }
    .subheader hgroup h1 {
      white-space: normal !important;
      width: inherit !important;
      margin: 0 !important; }
    .subheader hgroup h2 {
      width: calc(100vw - 40px);
      white-space: normal !important;
      margin: 3px 0 0 0 !important;
      line-height: 16px !important; }
  .subheader .subheader__left-part--api, .subheader .subheader__left-part--online, .subheader .subheader__left-part--mt, .subheader .subheader__left-part--mx, .subheader .subheader__left-part--sepa, .subheader .subheader__left-part--translator {
    display: none; }
  .subheader__auth {
    max-width: initial;
    padding-right: 10px; }
    .subheader__auth--wrapper {
      width: 100%; }
      .subheader__auth--wrapper__unauthed {
        justify-content: flex-end; }
      .subheader__auth--wrapper__authed .subheader__auth {
        width: 34px; }
      .subheader__auth--wrapper__authed .subheader__profile {
        justify-content: flex-end; }
    .subheader__auth--logout {
      width: 34px;
      margin-right: -10px; }
    .subheader__auth--link__signup {
      margin-right: -20px; }
  .subheader__profile {
    width: inherit; }
    .subheader__profile--plan span:nth-child(n + 2) {
      display: none; }
    .subheader__profile--subscription {
      padding: 12px 12px 12px 0;
      width: 45vw; }
    .subheader__profile--email {
      font-size: 12px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 30px);
      text-align: right; }
    .subheader__profile--trials {
      margin-right: 2px;
      padding: 0 10px; }
  .subheader .left__part {
    min-width: initial !important; } }

@media only screen and (max-width: 360px) {
  header .logo {
    margin: 0 -6px 0 auto; } }

/* Message illustration animation - homepage */
@-webkit-keyframes sdk-response-slow {
  0% {
    -webkit-transform: scale(1); }
  40% {
    -webkit-transform: scale(1); }
  42% {
    -webkit-transform: scale(1.18); }
  45% {
    -webkit-transform: scale(1); }
  100% {
    -webkit-transform: scale(1); } }

@-moz-keyframes sdk-response-slow {
  0% {
    -moz-transform: scale(1); }
  40% {
    -moz-transform: scale(1); }
  42% {
    -moz-transform: scale(1.18); }
  45% {
    -moz-transform: scale(1); }
  100% {
    -moz-transform: scale(1); } }

@-o-keyframes sdk-response-slow {
  0% {
    -o-transform: scale(1); }
  40% {
    -o-transform: scale(1); }
  42% {
    -o-transform: scale(1.18); }
  45% {
    -o-transform: scale(1); }
  100% {
    -o-transform: scale(1); } }

@keyframes sdk-response-slow {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(1); }
  42% {
    transform: scale(1.18); }
  45% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes api-sdk-msg_sdk {
  0% {
    -webkit-transform: translateX(-28px); }
  50% {
    -webkit-transform: translateX(115px); }
  100% {
    -webkit-transform: translateX(-28px); } }

@-moz-keyframes api-sdk-msg_sdk {
  0% {
    -moz-transform: translateX(-28px); }
  50% {
    -moz-transform: translateX(115px); }
  100% {
    -moz-transform: translateX(-28px); } }

@-o-keyframes api-sdk-msg_sdk {
  0% {
    -o-transform: translateX(-28px); }
  50% {
    -o-transform: translateX(115px); }
  100% {
    -o-transform: translateX(-28px); } }

@keyframes api-sdk-msg_sdk {
  0% {
    transform: translateX(-28px); }
  50% {
    transform: translateX(115px); }
  100% {
    transform: translateX(-28px); } }

@-webkit-keyframes api-sdk-msg_api {
  0% {
    -webkit-transform: translateX(-28px); }
  50% {
    -webkit-transform: translateX(200px); }
  100% {
    -webkit-transform: translateX(-28px); } }

@-moz-keyframes api-sdk-msg_api {
  0% {
    -moz-transform: translateX(-28px); }
  50% {
    -moz-transform: translateX(200px); }
  100% {
    -moz-transform: translateX(-28px); } }

@-o-keyframes api-sdk-msg_api {
  0% {
    -o-transform: translateX(-28px); }
  50% {
    -o-transform: translateX(200px); }
  100% {
    -o-transform: translateX(-28px); } }

@keyframes api-sdk-msg_api {
  0% {
    transform: translateX(-28px); }
  50% {
    transform: translateX(200px); }
  100% {
    transform: translateX(-28px); } }

@-webkit-keyframes api-cog {
  0% {
    -webkit-transform: rotate(0deg); }
  20% {
    -webkit-transform: rotate(0deg); }
  21% {
    -webkit-transform: rotate(0deg); }
  32% {
    -webkit-transform: rotate(180deg); }
  42% {
    -webkit-transform: rotate(180deg); }
  53% {
    -webkit-transform: rotate(360deg); }
  63% {
    -webkit-transform: rotate(360deg); }
  74% {
    -webkit-transform: rotate(540deg); }
  100% {
    -webkit-transform: rotate(540deg); } }

@-moz-keyframes api-cog {
  0% {
    -o-transform: rotate(0deg); }
  20% {
    -o-transform: rotate(0deg); }
  21% {
    -moz-transform: rotate(0deg); }
  32% {
    -moz-transform: rotate(180deg); }
  42% {
    -moz-transform: rotate(180deg); }
  53% {
    -moz-transform: rotate(360deg); }
  63% {
    -moz-transform: rotate(360deg); }
  74% {
    -moz-transform: rotate(540deg); }
  100% {
    -moz-transform: rotate(540deg); } }

@-o-keyframes api-cog {
  0% {
    -o-transform: rotate(0deg); }
  20% {
    -o-transform: rotate(0deg); }
  21% {
    -o-transform: rotate(0deg); }
  32% {
    -o-transform: rotate(180deg); }
  42% {
    -o-transform: rotate(180deg); }
  53% {
    -o-transform: rotate(360deg); }
  63% {
    -o-transform: rotate(360deg); }
  74% {
    -o-transform: rotate(540deg); }
  100% {
    -o-transform: rotate(540deg); } }

@keyframes api-cog {
  0% {
    transform: rotate(0deg); }
  20% {
    transform: rotate(0deg); }
  21% {
    transform: rotate(0deg); }
  32% {
    transform: rotate(180deg); }
  42% {
    transform: rotate(180deg); }
  53% {
    transform: rotate(360deg); }
  63% {
    transform: rotate(360deg); }
  74% {
    transform: rotate(540deg); }
  100% {
    transform: rotate(540deg); } }

@-webkit-keyframes sdk-response-fast {
  0% {
    -webkit-transform: scale(1); }
  40% {
    -webkit-transform: scale(1); }
  44% {
    -webkit-transform: scale(1.18); }
  48% {
    -webkit-transform: scale(1); }
  63% {
    -webkit-transform: scale(1); }
  67% {
    -webkit-transform: scale(1.18); }
  71% {
    -webkit-transform: scale(1); }
  86% {
    -webkit-transform: scale(1); }
  90% {
    -webkit-transform: scale(1.18); }
  94% {
    -webkit-transform: scale(1); } }

@-moz-keyframes sdk-response-fast {
  0% {
    -moz-transform: scale(1); }
  40% {
    -moz-transform: scale(1); }
  44% {
    -moz-transform: scale(1.18); }
  48% {
    -moz-transform: scale(1); }
  63% {
    -moz-transform: scale(1); }
  67% {
    -moz-transform: scale(1.18); }
  71% {
    -moz-transform: scale(1); }
  86% {
    -moz-transform: scale(1); }
  90% {
    -moz-transform: scale(1.18); }
  94% {
    -moz-transform: scale(1); } }

@-o-keyframes sdk-response-fast {
  0% {
    -o-transform: scale(1); }
  40% {
    -o-transform: scale(1); }
  44% {
    -o-transform: scale(1.18); }
  48% {
    -o-transform: scale(1); }
  63% {
    -o-transform: scale(1); }
  67% {
    -o-transform: scale(1.18); }
  71% {
    -o-transform: scale(1); }
  86% {
    -o-transform: scale(1); }
  90% {
    -o-transform: scale(1.18); }
  94% {
    -o-transform: scale(1); } }

@keyframes sdk-response-fast {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(1); }
  44% {
    transform: scale(1.18); }
  48% {
    transform: scale(1); }
  63% {
    transform: scale(1); }
  67% {
    transform: scale(1.18); }
  71% {
    transform: scale(1); }
  86% {
    transform: scale(1); }
  90% {
    transform: scale(1.18); }
  94% {
    transform: scale(1); } }

.homepage {
  width: 100%;
  background-color: #F4FDFF; }
  .homepage .hero {
    width: 100%;
    height: auto;
    min-height: 427px;
    position: relative;
    background-color: #011C29;
    padding: calc(2.8rem + 2.8vw + 6px) 20px 80px 20px;
    overflow: hidden;
    text-align: center;
    letter-spacing: -0.5px; }
    .homepage .hero hgroup {
      color: #ffffff;
      white-space: pre-wrap; }
      .homepage .hero hgroup h1 {
        font: normal normal 500 64px/54px "Montserrat", sans-serif; }
      .homepage .hero hgroup h2 {
        font: normal normal 300 32px/54px "Montserrat", sans-serif;
        margin: 12px 0 0 0;
        word-spacing: 2px;
        letter-spacing: 1px; }
  .homepage .info {
    padding: 90px 20px 48px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .homepage .info > h3 {
      font: normal normal 400 38px/48px "Montserrat", sans-serif;
      color: #166C81;
      margin: 25px 0 90px 0;
      max-width: 1080px;
      text-align: center; }
    .homepage .info__container {
      width: calc(100% - 22vw);
      margin: 0 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch; }
    .homepage .info__item {
      margin: 0 0 80px 0;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center; }
      .homepage .info__item article {
        width: calc(100% - 50px);
        max-width: 580px;
        margin: 0; }
        .homepage .info__item article h4 {
          text-align: center;
          font: normal normal 500 34px/1.2 "Montserrat", sans-serif;
          margin: 5px 0 10px 0;
          color: #033646;
          white-space: nowrap; }
    .homepage .info__text--caption {
      color: #3D8394;
      margin: 35px 0 3rem 0;
      font: normal normal 500 20px/30px "Montserrat", sans-serif; }
    .homepage .info__text--paragraph {
      color: #033646;
      font: normal normal 300 20px/28px "Montserrat", sans-serif;
      margin: 18px 0 30px 0; }
      .homepage .info__text--paragraph:not(.info__text--paragraph-apis_2):not(.info__text--paragraph-apis_3) {
        margin: 18px 0 30px 0; }
      .homepage .info__text--paragraph-apis_2 {
        margin: 18px 0 0 0; }
      .homepage .info__text--paragraph-apis_3 {
        margin: 0 0 30px 0; }
      .homepage .info__text--paragraph > a {
        color: #033646;
        text-decoration: none !important;
        position: relative; }
        .homepage .info__text--paragraph > a:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 8px;
          bottom: 0;
          left: 0;
          background-color: rgba(152, 216, 230, 0.6); }
        .homepage .info__text--paragraph > a:hover {
          color: #055c77; }
          .homepage .info__text--paragraph > a:hover:after {
            background-color: rgba(173, 224, 235, 0.6); }
    .homepage .info .illustration {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      margin: 32px auto 0 auto; }
      .homepage .info .illustration__apis {
        width: 360px;
        height: 260px; }
      .homepage .info .illustration__fin-messages {
        width: 260px;
        height: 260px; }
      .homepage .info .illustration__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .homepage .info .illustration__content--apis, .homepage .info .illustration__content--fin-messages {
          height: 226px; }
      .homepage .info .illustration__caption {
        text-align: center;
        color: #3D8394;
        font: normal normal 300 14px/30px "Montserrat", sans-serif; }
        .homepage .info .illustration__caption--apis {
          margin: -10px 0 40px 3px; }
        .homepage .info .illustration__caption--fin-messages {
          margin: -10px 0 40px -50px; }
      .homepage .info .illustration__background {
        position: absolute;
        width: 100%;
        height: 100%; }
        .homepage .info .illustration__background--circle-intersection {
          top: 0;
          left: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
        .homepage .info .illustration__background--circle-subset {
          top: 0;
          left: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
      .homepage .info .illustration__circle-intersection {
        border-radius: 50%;
        width: 225px;
        height: 225px; }
        .homepage .info .illustration__circle-intersection--dark {
          mix-blend-mode: multiply;
          background-color: #3D8394;
          margin-left: -124px;
          top: 0; }
        .homepage .info .illustration__circle-intersection--light {
          mix-blend-mode: normal;
          background-color: #92C4D0;
          left: 0;
          top: 0; }
      .homepage .info .illustration__circle-subset {
        border-radius: 50%; }
        .homepage .info .illustration__circle-subset--dark {
          width: 144px;
          height: 144px;
          position: absolute;
          top: 46px;
          right: 36px;
          mix-blend-mode: multiply;
          background-color: #3D8394; }
        .homepage .info .illustration__circle-subset--light {
          width: 226px;
          height: 226px;
          mix-blend-mode: normal;
          background-color: #92C4D0;
          left: 0;
          top: 0; }
      .homepage .info .illustration__animation {
        position: relative;
        height: inherit;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .homepage .info .illustration__animation--message {
          width: 9px;
          height: 9px;
          background-color: #ffffff;
          border-radius: 50%; }
        .homepage .info .illustration__animation--sdk-container, .homepage .info .illustration__animation--code {
          z-index: 3; }
        .homepage .info .illustration__animation--sdk-text {
          z-index: 3; }
        .homepage .info .illustration__animation--api-container {
          transform: rotate(0deg); }
        .homepage .info .illustration__animation--api-text {
          z-index: 3; }
        .homepage .info .illustration__animation--apis-message {
          position: absolute;
          left: 95px; }
          .homepage .info .illustration__animation--apis-message.__safari {
            margin: -2px 0 0 0 !important; }
          @supports (-webkit-touch-callout: none) {
            .homepage .info .illustration__animation--apis-message {
              margin: -3px 0 0 0; } }
          @supports not (-webkit-touch-callout: none) {
            .homepage .info .illustration__animation--apis-message {
              margin: -2px 0 0 0; } }
        .homepage .info .illustration__animation--apis-code {
          margin: -1px 0 0 51px; }
        .homepage .info .illustration__animation--apis-input {
          margin: -1px 0 0 -2px; }
        .homepage .info .illustration__animation--apis-output {
          margin: -1px 1px 0 7px; }
        .homepage .info .illustration__animation--apis-api-container {
          margin: -1px 0 0 -7px; }
        .homepage .info .illustration__animation--apis-api-text {
          margin: -1px 0 0 -30px; }
        .homepage .info .illustration__animation--apis-sdk-container {
          margin: -1px 0 0 -7px; }
        .homepage .info .illustration__animation--apis-sdk-text {
          margin: 0 0 0 -38px;
          transform: scale(1); }
        .homepage .info .illustration__animation--apis__sdk-fill {
          background-color: #397B8B;
          position: absolute;
          right: -12px;
          height: 32px;
          width: 48px;
          margin: 20px 0 0 0;
          z-index: 2; }
        .homepage .info .illustration__animation--fin-messages-message {
          position: absolute;
          left: 80px; }
          .homepage .info .illustration__animation--fin-messages-message.__safari {
            margin: 15px 0 0 0 !important; }
          @supports (-webkit-touch-callout: none) {
            .homepage .info .illustration__animation--fin-messages-message {
              margin: 18px 0 0 0; } }
          @supports not (-webkit-touch-callout: none) {
            .homepage .info .illustration__animation--fin-messages-message {
              margin: 15px 0 0 0; } }
        .homepage .info .illustration__animation--fin-messages-code {
          margin: 17px 0 0 20px; }
        .homepage .info .illustration__animation--fin-messages-input {
          margin: 17px 0 0 0; }
        .homepage .info .illustration__animation--fin-messages-output {
          margin: 17px -3px 0 6px; }
        .homepage .info .illustration__animation--fin-messages-api-container {
          margin: 17px 0 0 -4px; }
        .homepage .info .illustration__animation--fin-messages-api-text {
          margin: 17px 0 0 -21px; }
        .homepage .info .illustration__animation--fin-messages-sdk-container {
          margin: 16px 0 0 4px; }
        .homepage .info .illustration__animation--fin-messages-sdk-text {
          margin: 8px 0 -8px -27px;
          transform: scale(1); }
        .homepage .info .illustration__animation--fin-messages__sdk-fill {
          background-color: #236579;
          position: absolute;
          right: -12px;
          height: 28px;
          width: 38px;
          margin: 20px 0 0 0;
          z-index: 2; }
      .homepage .info .illustration__apis .illustration__animation--apis-message__a {
        -webkit-animation: api-sdk-msg_api 1.8s linear 0s infinite;
        -moz-animation: api-sdk-msg_api 1.8s linear 0s infinite;
        -o-animation: api-sdk-msg_api 1.8s linear 0s infinite;
        animation: api-sdk-msg_api 1.8s linear 0s infinite; }
      .homepage .info .illustration__apis .illustration__animation--apis-message__b {
        -webkit-animation: api-sdk-msg_api 1.8s linear 0.4s infinite;
        -moz-animation: api-sdk-msg_api 1.8s linear 0.4s infinite;
        -o-animation: api-sdk-msg_api 1.8s linear 0.4s infinite;
        animation: api-sdk-msg_api 1.8s linear 0.4s infinite; }
      .homepage .info .illustration__apis .illustration__animation--apis-message__c {
        -webkit-animation: api-sdk-msg_api 1.8s linear 0.8s infinite;
        -moz-animation: api-sdk-msg_api 1.8s linear 0.8s infinite;
        -o-animation: api-sdk-msg_api 1.8s linear 0.8s infinite;
        animation: api-sdk-msg_api 1.8s linear 0.8s infinite; }
      .homepage .info .illustration__apis .illustration__animation--api-container {
        -webkit-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
        -moz-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
        -o-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
        animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite; }
      .homepage .info .illustration__apis .illustration__animation--sdk-text {
        -webkit-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
        -moz-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
        -o-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
        animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite; }
      .homepage .info .illustration__fin-messages .illustration__animation--fin-messages-message__a {
        -webkit-animation: api-sdk-msg_sdk 1.8s linear 0s infinite;
        -moz-animation: api-sdk-msg_sdk 1.8s linear 0s infinite;
        -o-animation: api-sdk-msg_sdk 1.8s linear 0s infinite;
        animation: api-sdk-msg_sdk 1.8s linear 0s infinite; }
      .homepage .info .illustration__fin-messages .illustration__animation--fin-messages-message__b {
        -webkit-animation: api-sdk-msg_sdk 1.8s linear 0.4s infinite;
        -moz-animation: api-sdk-msg_sdk 1.8s linear 0.4s infinite;
        -o-animation: api-sdk-msg_sdk 1.8s linear 0.4s infinite;
        animation: api-sdk-msg_sdk 1.8s linear 0.4s infinite; }
      .homepage .info .illustration__fin-messages .illustration__animation--fin-messages-message__c {
        -webkit-animation: api-sdk-msg_sdk 1.8s linear 0.8s infinite;
        -moz-animation: api-sdk-msg_sdk 1.8s linear 0.8s infinite;
        -o-animation: api-sdk-msg_sdk 1.8s linear 0.8s infinite;
        animation: api-sdk-msg_sdk 1.8s linear 0.8s infinite; }
      .homepage .info .illustration__fin-messages .illustration__animation--api-container {
        -webkit-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.2s infinite;
        -moz-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.2s infinite;
        -o-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.2s infinite;
        animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.2s infinite; }
      .homepage .info .illustration__fin-messages .illustration__animation--sdk-text {
        -webkit-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.1s infinite;
        -moz-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.1s infinite;
        -o-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.1s infinite;
        animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.1s infinite; }
      @media only screen and (min-width: 971px) {
        .homepage .info .illustration__apis:hover .illustration__animation--apis-message__a {
          -webkit-animation: api-sdk-msg_api 1.8s linear 0s infinite;
          -moz-animation: api-sdk-msg_api 1.8s linear 0s infinite;
          -o-animation: api-sdk-msg_api 1.8s linear 0s infinite;
          animation: api-sdk-msg_api 1.8s linear 0s infinite; }
        .homepage .info .illustration__apis:hover .illustration__animation--apis-message__b {
          -webkit-animation: api-sdk-msg_api 1.8s linear 0.4s infinite;
          -moz-animation: api-sdk-msg_api 1.8s linear 0.4s infinite;
          -o-animation: api-sdk-msg_api 1.8s linear 0.4s infinite;
          animation: api-sdk-msg_api 1.8s linear 0.4s infinite; }
        .homepage .info .illustration__apis:hover .illustration__animation--apis-message__c {
          -webkit-animation: api-sdk-msg_api 1.8s linear 0.8s infinite;
          -moz-animation: api-sdk-msg_api 1.8s linear 0.8s infinite;
          -o-animation: api-sdk-msg_api 1.8s linear 0.8s infinite;
          animation: api-sdk-msg_api 1.8s linear 0.8s infinite; }
        .homepage .info .illustration__apis:hover .illustration__animation--api-container {
          -webkit-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
          -moz-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
          -o-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
          animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite; }
        .homepage .info .illustration__apis:hover .illustration__animation--sdk-text {
          -webkit-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
          -moz-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
          -o-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite;
          animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0s infinite; }
        .homepage .info .illustration__fin-messages:hover .illustration__animation--fin-messages-message__a {
          -webkit-animation: api-sdk-msg_sdk 1.8s linear 0s infinite;
          -moz-animation: api-sdk-msg_sdk 1.8s linear 0s infinite;
          -o-animation: api-sdk-msg_sdk 1.8s linear 0s infinite;
          animation: api-sdk-msg_sdk 1.8s linear 0s infinite; }
        .homepage .info .illustration__fin-messages:hover .illustration__animation--fin-messages-message__b {
          -webkit-animation: api-sdk-msg_sdk 1.8s linear 0.4s infinite;
          -moz-animation: api-sdk-msg_sdk 1.8s linear 0.4s infinite;
          -o-animation: api-sdk-msg_sdk 1.8s linear 0.4s infinite;
          animation: api-sdk-msg_sdk 1.8s linear 0.4s infinite; }
        .homepage .info .illustration__fin-messages:hover .illustration__animation--fin-messages-message__c {
          -webkit-animation: api-sdk-msg_sdk 1.8s linear 0.8s infinite;
          -moz-animation: api-sdk-msg_sdk 1.8s linear 0.8s infinite;
          -o-animation: api-sdk-msg_sdk 1.8s linear 0.8s infinite;
          animation: api-sdk-msg_sdk 1.8s linear 0.8s infinite; }
        .homepage .info .illustration__fin-messages:hover .illustration__animation--api-container {
          -webkit-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.2s infinite;
          -moz-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.2s infinite;
          -o-animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.2s infinite;
          animation: api-cog 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.2s infinite; }
        .homepage .info .illustration__fin-messages:hover .illustration__animation--sdk-text {
          -webkit-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.1s infinite;
          -moz-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.1s infinite;
          -o-animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.1s infinite;
          animation: sdk-response-fast 1.8s cubic-bezier(0.88, 0.56, 0.07, 0.9) 0.1s infinite; } }
      .homepage .info .illustration__button--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 2px 0 0 0;
        height: 62px;
        position: relative;
        padding: 0 0 33px 0;
        width: 100%; }
        .homepage .info .illustration__button--wrapper > span {
          font: normal normal 600 13px/30px "Montserrat", sans-serif;
          color: #033646; }
      .homepage .info .illustration__button--container {
        position: absolute;
        top: 33px;
        min-width: 505px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center; }
        .homepage .info .illustration__button--container a {
          white-space: nowrap;
          margin: 0 10px 10px 10px; }
  .homepage .benefits {
    background-color: #166C81;
    min-height: 432px;
    padding: 88px 20px 97px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .homepage .benefits > h2 {
      color: #ffffff;
      margin: 10px 0 40px 0;
      text-align: center;
      font: normal normal 400 38px/1.2 "Montserrat", sans-serif; }
    .homepage .benefits__container {
      width: calc(100% - 24vw);
      max-width: 1200px;
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch; }
    .homepage .benefits article {
      color: #ffffff;
      width: calc(50% - 10px);
      max-width: 550px;
      margin: 35px 0 0 0; }
      .homepage .benefits article h3 {
        font: normal normal 500 26px/1 "Montserrat", sans-serif; }
      .homepage .benefits article p {
        font: normal normal 300 20px/28px "Montserrat", sans-serif;
        margin: 15px 0 10px 0; }
  .homepage .aplonhub {
    margin: 100px auto 70px auto; }
  .homepage .iso20022-guide {
    background-color: #B9DADA;
    margin: 132px 0 0 0;
    padding: 52px 20px 75px 20px; }
    .homepage .iso20022-guide__img--wrapper {
      position: relative;
      margin: 27px 58px 0 0; }
    .homepage .iso20022-guide__img--comparison {
      -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.28) !important;
      -moz-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.28) !important;
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.28) !important; }
    .homepage .iso20022-guide__img--detail {
      position: absolute;
      top: -21px;
      right: -56px; }
    .homepage .iso20022-guide__container {
      width: calc(100% - 24vw);
      max-width: 1215px;
      margin: 10px auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .homepage .iso20022-guide__container article {
        margin: -8px 0 0 35px;
        width: calc(100% + 35px); }
        .homepage .iso20022-guide__container article > h5 {
          font: normal normal 400 38px/48px "Montserrat", sans-serif;
          color: #166C81;
          width: calc(100% + 35px); }
        .homepage .iso20022-guide__container article > p {
          font: normal normal 500 20px/30px "Montserrat", sans-serif;
          color: #033646;
          max-width: 574px;
          margin: 13px 0 20px 5px; }

.fintechs__logos {
  padding: 16px 30px;
  width: 100%;
  position: absolute;
  bottom: 15px;
  margin-left: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .fintechs__logos article {
    width: 100%; }
  .fintechs__logos--container {
    height: 26px; }
  .fintechs__logos--content {
    background: url("../img/fintechs_logos.svg") center 0/contain no-repeat;
    height: 26px; }

@media only screen and (max-width: 1620px) {
  .homepage .info__container {
    width: calc(100% - 40px); }
  .homepage .iso20022-guide__container {
    width: calc(100% - 60px); } }

@media only screen and (min-width: 1126px) and (max-width: 1318px) {
  .homepage .info__text--caption {
    height: 90px !important; } }

@media only screen and (max-width: 1125px) {
  .homepage .info__container {
    width: calc(100% - 20px);
    flex-wrap: wrap; }
  .homepage .info__item {
    width: 100%; }
    .homepage .info__item h4 {
      white-space: normal !important; }
  .homepage .benefits__container article {
    max-width: initial !important; } }

@media only screen and (max-width: 970px) {
  .homepage .hero {
    padding: calc(2vw + 25px) 20px 30px 20px;
    min-height: auto; }
    .homepage .hero hgroup {
      white-space: normal; }
      .homepage .hero hgroup h1 {
        font-size: 34px !important;
        line-height: 1.2 !important; }
      .homepage .hero hgroup h2 {
        font: normal normal 300 21px/1.3 "Montserrat", sans-serif;
        letter-spacing: 0;
        word-spacing: 0;
        margin: 12px 0 45px 0; }
  .homepage .info {
    padding: 20px; }
    .homepage .info > h3 {
      margin: 30px 0 50px 0 !important;
      font-size: 30px !important;
      line-height: 1.3 !important; }
    .homepage .info__text--caption {
      margin: 30px 0 2rem 0; }
    .homepage .info__item {
      position: relative;
      padding: 0 0 30px 0;
      margin: 0 0 55px 0; }
      .homepage .info__item--fin-messages {
        flex-direction: column; }
      .homepage .info__item--apis {
        flex-direction: column; }
      .homepage .info__item article {
        width: calc(100vw - 40px);
        max-width: initial; }
        .homepage .info__item article > h4 {
          font-size: 28px !important; }
    .homepage .info__text--caption {
      text-align: center; }
    .homepage .info__text--paragraph {
      margin: 15px 0; }
    .homepage .info .illustration {
      transform: scale(0.78);
      margin: -5px auto 0 auto !important;
      height: initial;
      margin-bottom: -30px !important; }
      .homepage .info .illustration__caption {
        margin: 40px 0 50px 0;
        font: normal normal 300 16px/1.2 "Montserrat", sans-serif; }
      .homepage .info .illustration__content--fin-messages {
        margin-top: -15px; }
      .homepage .info .illustration__button--container {
        flex-wrap: wrap;
        min-width: initial; }
      .homepage .info .illustration__button--wrapper {
        margin-top: 0; }
  .homepage .benefits {
    padding: 60px 20px 40px 20px; }
    .homepage .benefits h2 {
      font-size: 32px !important; }
    .homepage .benefits__container {
      width: 100%; }
    .homepage .benefits article {
      width: calc(100% - 10px);
      margin: 15px 0 15px 0; }
  .homepage .aplonhub {
    margin: 70px auto 35px auto; }
  .homepage .iso20022-guide {
    margin: 50px 0 0 0;
    padding: 52px 20px 45px; }
    .homepage .iso20022-guide__img--wrapper {
      margin: 45px auto 20px auto;
      width: 242px !important; }
    .homepage .iso20022-guide__img--comparison {
      height: auto;
      width: 100%; }
    .homepage .iso20022-guide__img--detail {
      height: auto;
      width: 110px;
      top: -15px;
      right: -38px; }
    .homepage .iso20022-guide__container {
      flex-direction: column-reverse;
      width: calc(100vw - 40px) !important; }
      .homepage .iso20022-guide__container > article {
        width: calc(100vw - 40px) !important;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .homepage .iso20022-guide__container > article > h5 {
          max-width: 574px !important;
          width: initial !important;
          font: normal normal 400 28px/1.2 "Montserrat", sans-serif; }
        .homepage .iso20022-guide__container > article > p {
          width: initial !important;
          margin: 13px auto 20px auto; }
  .fintechs__logos {
    padding: 16px 20px;
    margin: 0 0 -8px -20px; }
    .fintechs__logos--container {
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 !important;
      margin: 0 0 0 -20px;
      width: calc(100% + 40px); }
    .fintechs__logos--content {
      max-width: unset !important;
      background: scroll url("../img/fintechs_logos.svg") 0 0/contain repeat-x !important;
      width: 4660px; } }

.footer {
  width: 100%;
  height: auto;
  min-height: 240px;
  bottom: 0;
  z-index: 2;
  padding: 10px 20px 10px 5vw;
  background-color: #003646;
  color: #ffffff !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .footer img {
    margin: 10px 11px 0 10px; }
  .footer h5,
  .footer h6 {
    font: normal normal 500 14px/20px "Montserrat", sans-serif;
    margin: 0 0 3px 0;
    white-space: nowrap; }
  .footer__content {
    position: relative;
    width: 100%;
    margin: 0 auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; }
  .footer__block {
    padding: 0 5px;
    max-width: 300px; }
    .footer__block--0 {
      margin: 10px auto 10px 0; }
    .footer__block--1 {
      margin: 10px auto 10px auto; }
    .footer__block--2 {
      margin: 10px auto 10px auto; }
    .footer__block--contact {
      margin: 10px auto 10px auto; }
    .footer__block a {
      font: normal normal 300 12px/24px "Montserrat", sans-serif;
      color: #ffffff !important;
      white-space: nowrap; }
    .footer__block--links {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start; }
    .footer__block--contact {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
      .footer__block--contact span {
        font: normal normal 300 12px/24px "Montserrat", sans-serif; }
    .footer__block--mail-phone {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
    .footer__block--social {
      margin-left: -5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch; }
      .footer__block--social a {
        margin: 4px 4px -4px 4px;
        line-height: 20px; }
        .footer__block--social a span {
          display: none; }
  .footer__col {
    width: calc(55% - 20px);
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start; }

.social-icon {
  background-image: url("../img/css-sprites-social.png");
  background-repeat: no-repeat;
  display: block;
  width: 23px;
  height: 23px; }
  .social-icon__facebook {
    background-position: -10px -10px; }
  .social-icon__linkedin {
    background-position: -53px -10px; }
  .social-icon__twitter {
    background-position: -10px -53px; }
  .social-icon__youtube {
    background-position: -53px -53px; }

@media only screen and (max-width: 1407px) and (min-width: 971px) {
  .footer__block--contact {
    min-width: 360px;
    margin: 10px auto 10px 60px; } }

@media only screen and (max-width: 970px) {
  .footer {
    padding: 10px 20px; }
    .footer__content {
      flex-direction: column;
      align-items: center;
      width: calc(100% - 9vw);
      margin: 0 auto;
      padding: 0; }
    .footer__block {
      width: 206px;
      min-width: initial;
      margin: 0 auto; }
      .footer__block--1 {
        display: none; }
      .footer__block--links {
        justify-content: space-between; }
      .footer__block--links, .footer__block--mail-phone {
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height 0.25s ease-in-out;
        -moz-transition: max-height 0.25s ease-in-out;
        -o-transition: max-height 0.25s ease-in-out;
        -ms-transition: max-height 0.25s ease-in-out;
        transition: max-height 0.25s ease-in-out; }
      .footer__block--open {
        max-height: 72px;
        -webkit-transition: max-height 0.25s ease-in-out;
        -moz-transition: max-height 0.25s ease-in-out;
        -o-transition: max-height 0.25s ease-in-out;
        -ms-transition: max-height 0.25s ease-in-out;
        transition: max-height 0.25s ease-in-out; }
      .footer__block--social {
        height: 28px;
        width: 100%;
        margin: 20px 0 15px 0; }
    .footer__col {
      margin: 0; }
    .footer img {
      margin: 20px auto 10px auto; }
    .footer h5,
    .footer h6 {
      font-size: 16px;
      width: 100%;
      text-align: center;
      padding: 10px 0; } }

.checker-api {
  position: relative; }
  .checker-api__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100vw;
    height: 100%;
    margin: 0 0 0 auto;
    padding-bottom: 140px; }
  .checker-api__block {
    width: 50%;
    height: initial; }
  .checker-api__instructions {
    color: #033646; }
    .checker-api__instructions article {
      max-width: 500px;
      margin: 0 auto;
      padding: 55px 0 35px 0; }
      .checker-api__instructions article .subpage__titles {
        display: none; }
    .checker-api__instructions h3 {
      font: normal normal 600 16px/22px "Montserrat", sans-serif; }
    .checker-api__instructions ol {
      padding: 0;
      list-style-position: inside;
      margin: 25px 0 20px 0; }
  .checker-api__how-to {
    font: normal normal 300 16px/24px "Montserrat", sans-serif; }
  .checker-api__urls {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    margin-bottom: 5px; }
    .checker-api__urls > span:nth-child(odd) {
      font: normal normal 400 13px/20px "Montserrat", sans-serif; }
    .checker-api__urls > span:nth-child(even) {
      font: normal normal 300 12px/20px "Montserrat", sans-serif; }
  .checker-api__sandbox-spec {
    max-width: 375px;
    font: normal normal 300 10px/14px "Montserrat", sans-serif; }
  .checker-api__buttons {
    flex-wrap: wrap;
    max-width: 390px;
    margin: 25px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .checker-api__button {
    margin-top: 20px;
    white-space: nowrap; }
  .checker-api__copy-key {
    margin-top: 20px;
    width: 200px;
    height: 28px;
    border: 1px solid #3D8394;
    background-color: #000000;
    border-right: 0;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .checker-api__copy-key--text {
      font: normal italic 300 12px/1 "Montserrat", sans-serif;
      color: #27D7FF;
      padding: 0 4px 0 10px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .checker-api__copy-key--button {
      font: normal normal 400 12px/1 "Montserrat", sans-serif;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 26px;
      padding: 8px;
      color: #ffffff !important;
      letter-spacing: 0.3px;
      border: 1px solid #3D8394;
      background-color: #3D8394;
      border-radius: 13px; }
      .checker-api__copy-key--button:hover {
        background-color: #25505a !important;
        border: 1px solid #25505a !important; }
      .checker-api__copy-key--button:active, .checker-api__copy-key--button:focus {
        background-color: #3D8394 !important;
        border: 1px solid #3D8394 !important;
        color: #033646 !important; }
      .checker-api__copy-key--button:focus {
        box-shadow: none !important; }
    .checker-api__copy-key--copied {
      font: normal normal 400 10px/1 "Montserrat", sans-serif;
      text-align: center;
      position: absolute;
      width: 48px;
      top: -12px;
      right: 0; }
      .checker-api__copy-key--copied[data-flicker='1'] {
        animation: flicker 0.2s 1; }
    .checker-api__copy-key--note {
      margin-top: 15px;
      max-width: 390px;
      color: #033646;
      font: normal normal 300 10px/14px "Montserrat", sans-serif; }
    .checker-api__copy-key--error {
      color: #f36c9b; }
      .checker-api__copy-key--error a {
        color: #f36c9b; }
  .checker-api__prompt article {
    max-width: 530px;
    white-space: pre-wrap; }
    .checker-api__prompt article a {
      color: #ffffff !important;
      background-color: #27D7FF; }
      .checker-api__prompt article a:hover {
        background-color: #ffffff;
        color: #033646 !important; }
  .checker-api__faq {
    background-color: #ffffff;
    margin-bottom: -4px; }
  .checker-api__swagger {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    padding-bottom: 140px;
    background-color: #ffffff;
    flex: 0 1 100%;
    z-index: 1;
    scrollbar-color: #60C49F #DCEEE9;
    /* width */
    /* Track */
    /* Handle */ }
    .checker-api__swagger ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      border-radius: 30px; }
    .checker-api__swagger ::-webkit-scrollbar-track {
      background: #DCEEE9;
      border-radius: 30px; }
    .checker-api__swagger ::-webkit-scrollbar-thumb {
      background: #60C49F;
      border-radius: 30px; }
    .checker-api__swagger article {
      overflow-y: auto;
      height: 100%;
      margin-right: 17px; }
  .checker-api__question {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 140px;
    z-index: 2; }
    .checker-api__question article {
      width: 50vw;
      height: 100%; }
  .checker-api__swag-fail {
    font: normal normal 600 21px/1.4 "Montserrat", sans-serif;
    width: calc(100% - 40px);
    height: 400px;
    margin: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f07178; }

.swagger-ui .scheme-container .servers-title,
.swagger-ui .scheme-container .servers {
  margin-left: 15px; }

.swagger-ui .scheme-container .schemes {
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between; }

.swagger-ui .scheme-container .auth-wrapper {
  margin-top: 20px;
  margin-right: 5px; }

.swagger-ui .servers > label select {
  min-width: 30vw;
  max-width: 580px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: inherit; }

.swagger-ui .opblock.opblock-post .opblock-summary {
  background-color: #DCEEE9;
  border-color: #60C49F; }
  .swagger-ui .opblock.opblock-post .opblock-summary-method {
    background-color: #60C49F; }

.swagger-ui .opblock.opblock-get .opblock-summary {
  background-color: #DCE6EE;
  border-color: #49AACC; }
  .swagger-ui .opblock.opblock-get .opblock-summary-method {
    background-color: #49AACC; }

.swagger-ui .opblock-body {
  scrollbar-color: #8DA1A7 #2C2C2C;
  /* width */
  /* Track */
  /* Handle */ }
  .swagger-ui .opblock-body ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    border-radius: 0; }
  .swagger-ui .opblock-body ::-webkit-scrollbar-track {
    background: #2C2C2C;
    border-radius: 0; }
  .swagger-ui .opblock-body ::-webkit-scrollbar-thumb {
    background: #8DA1A7;
    border-radius: 0; }
  .swagger-ui .opblock-body pre.microlight {
    width: auto !important; }

@keyframes flicker {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(0.5); }
  100% {
    transform: scale(1); } }

@media only screen and (max-width: 1249px) {
  .checker-api__content {
    flex-wrap: nowrap; }
  .checker-api__block {
    width: 100%; }
  .checker-api__instructions {
    order: 1; }
  .checker-api__buttons {
    justify-content: space-between;
    max-width: 460px; }
  .checker-api__button {
    width: 165px; }
  .checker-api__copy-key {
    width: 165px; }
  .checker-api__swagger {
    position: static;
    order: 2;
    width: 100%;
    height: 300px;
    flex: initial;
    overflow: hidden;
    padding-bottom: 0 !important; }
    .checker-api__swagger article {
      position: relative;
      overflow: auto;
      height: 100%;
      margin: 0; }
      .checker-api__swagger article .swagger-ui {
        width: 700px;
        padding-bottom: 30px; }
  .checker-api__prompt {
    order: 3; }
    .checker-api__prompt article {
      white-space: normal; }
  .checker-api__instructions, .checker-api__prompt {
    padding-right: 20px;
    padding-left: 20px; }
    .checker-api__instructions article, .checker-api__prompt article {
      max-width: 600px;
      width: 100%; }
  .checker-api__instructions article {
    padding: 20px 0 35px 0 !important; }
    .checker-api__instructions article .subpage__titles {
      display: block !important;
      margin: 0 0 35px 0; }
  .checker-api__faq {
    order: 4; }
    .checker-api__faq .faq__accordion--title {
      width: 100%; }
  .checker-api__question article {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .checker-api__question article a {
      padding: 11px 22px;
      font-size: 16px;
      border-radius: 22px;
      width: auto;
      height: auto; } }

.checker {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  flex-wrap: nowrap;
  background-color: #DCE6E8; }
  .checker__row {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    flex-wrap: nowrap; }
    .checker__row--logs {
      background-color: #333542; }
    .checker__row--mobile .left__part,
    .checker__row--mobile .right__part {
      display: none; }
    .checker__row--mobile .middle__part {
      width: 100vw;
      margin: 0 auto; }
      .checker__row--mobile .middle__part .faq__content h4 {
        display: none; }
  .checker__container .CodeMirror {
    padding: 0 0 13px 0 !important;
    margin: 13px 0 0 0 !important; }
  .checker__container--false-mobile {
    display: block;
    margin: 40px;
    width: calc(100% - 40px); }
    .checker__container--false-mobile p {
      color: #003646;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: center;
      font: normal normal 500 24px/1.4 "Montserrat", sans-serif; }
    .checker__container--false-mobile .page__title,
    .checker__container--false-mobile .page__subtitle {
      position: absolute;
      opacity: 0; }
  .checker__container--mobile {
    width: 100%;
    background-color: #EFEFEF;
    min-height: calc(100vh - 100px - 240px); }
    .checker__container--mobile article {
      width: 100vw;
      position: relative;
      padding: 30px 25px 20px 20px; }
    .checker__container--mobile__web-prompt {
      background-image: linear-gradient(145deg, #49AACC, #49AACC 5%, #3D8394 50%); }
      .checker__container--mobile__web-prompt h1 {
        font: normal normal 500 22px/24px "Montserrat", sans-serif;
        color: #ffffff;
        white-space: pre-wrap;
        margin-bottom: 3px; }
      .checker__container--mobile__web-prompt h2 {
        font: normal normal 300 16px/20px "Montserrat", sans-serif;
        color: #ffffff; }
      .checker__container--mobile__web-prompt div {
        position: relative;
        margin: 22px auto 15px auto;
        width: calc(100vw - 50px);
        min-width: 300px; }
        .checker__container--mobile__web-prompt div img {
          width: calc(100% + 3px);
          height: auto;
          margin-left: -3px;
          -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.53) !important;
          -moz-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.53) !important;
          box-shadow: 0 3px 20px rgba(0, 0, 0, 0.53) !important; }
        .checker__container--mobile__web-prompt div p {
          position: absolute;
          width: 150px;
          height: 150px;
          margin: calc(9vw + 32px) calc(50% - 81px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          background-color: #098CB3;
          padding: 20px; }
          .checker__container--mobile__web-prompt div p span {
            text-align: center;
            color: #ffffff;
            font: normal normal 600 14px/18px "Montserrat", sans-serif; }
    .checker__container--mobile__videos {
      background-color: #EFEFEF; }
      .checker__container--mobile__videos h3 {
        color: #033646;
        font: normal normal 400 18px/26px "Montserrat", sans-serif;
        text-align: center; }
      .checker__container--mobile__videos .video:first-child {
        margin: 5px auto 40px auto; }
      .checker__container--mobile__videos .video:nth-child(2) {
        margin: 5px auto 20px auto; }
  .checker__faq h4 {
    margin: 60px 0 5px 0; }
  .checker__email-comment--offer__wrapper {
    width: calc(100% - 20px);
    max-width: 360px; }
  .checker__offer {
    display: block;
    margin: 50px 0 20px 20px;
    background-color: #242931;
    padding: 21px 19px;
    width: 100%;
    text-decoration: none !important; }
    .checker__offer--hdr-img i {
      margin: 2px 8px 0 0; }
    .checker__offer--hdr-img span {
      font: normal normal 600 16px/19px "Montserrat", sans-serif;
      color: #ffffff;
      margin: 2px 0 0 0; }
    .checker__offer p {
      color: #ffffff;
      font: normal normal 400 12px/18px "Montserrat", sans-serif;
      margin: 17px 0 0 0;
      max-width: 234px; }

.msg-hint {
  color: #ffffff;
  font: normal normal 600 16px/1.2 Menlo, Monaco, Consolas, "Courier New", monospace;
  margin: 13px 0 -10px 40px;
  z-index: 10; }

.msg-content {
  background-image: linear-gradient(#1E1E1E 50%, #034558 50%);
  padding: 0 0 15px 0;
  position: relative;
  height: 636px;
  white-space: pre-wrap;
  scrollbar-color: #424242 #1E1E1E;
  /* width */
  /* Track */
  /* Handle */ }
  .msg-content ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    border-radius: 0; }
  .msg-content ::-webkit-scrollbar-track {
    background: #1E1E1E;
    border-radius: 0; }
  .msg-content ::-webkit-scrollbar-thumb {
    background: #424242;
    border-radius: 0; }
  .msg-content--mt p, .msg-content--mx p, .msg-content--sepa p, .msg-content--targetTwo p, .msg-content--cbpr p {
    max-width: 500px; }
  .msg-content--translator p, .msg-content--cbpr-plus p {
    max-width: 680px; }
  .msg-content p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 30px;
    height: 55px;
    color: #ffffff;
    white-space: nowrap;
    position: relative; }
    .msg-content p.msg-content__title {
      font: normal normal 600 14px/19px Menlo, Monaco, Consolas, "Courier New", monospace; }
    .msg-content p:not(.msg-content__title) {
      font: normal normal 600 12px/14px Menlo, Monaco, Consolas, "Courier New", monospace; }
    .msg-content p:before {
      content: "";
      position: absolute;
      display: block;
      height: inherit;
      top: 0;
      width: 100%;
      border-bottom: 2px dashed #ffffff; }
    .msg-content p > span {
      letter-spacing: 0.4px; }
  .msg-content textarea {
    font: normal normal 400 13px/16px Menlo, Monaco, Consolas, "Courier New", monospace;
    background-color: #1E1E1E;
    margin: 0 0 23px 0;
    display: block;
    width: 100%;
    height: 385px !important;
    padding: 13px 28px;
    outline: none !important;
    resize: none;
    position: relative;
    white-space: pre-wrap;
    overflow-y: auto;
    text-indent: -8px;
    border: 2px solid transparent;
    -webkit-transition: border-color 0.9s cubic-bezier(0.04, 0.94, 0.04, 0.94);
    -moz-transition: border-color 0.9s cubic-bezier(0.04, 0.94, 0.04, 0.94);
    -o-transition: border-color 0.9s cubic-bezier(0.04, 0.94, 0.04, 0.94);
    -ms-transition: border-color 0.9s cubic-bezier(0.04, 0.94, 0.04, 0.94);
    transition: border-color 0.9s cubic-bezier(0.04, 0.94, 0.04, 0.94); }
    .msg-content textarea.invalid-txt {
      border-color: #f03a17; }
  .msg-content__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 calc(15px + 0.25vw);
    margin: 28px 0 0 0; }
    .msg-content__bottom > .btn {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      background-color: transparent;
      border-color: #ffffff;
      margin-left: 0 !important; }
      .msg-content__bottom > .btn.clear-btn-min-width-a {
        min-width: 80px; }
      .msg-content__bottom > .btn:hover:not(:disabled) {
        color: #034558 !important;
        background-color: #ffffff !important; }
      .msg-content__bottom > .btn:active:not(:disabled) {
        color: #002530 !important;
        background-color: #026986 !important;
        border-color: #026986 !important; }
      .msg-content__bottom > .btn:disabled {
        color: #4CA5BA;
        background-color: #034558;
        border-color: rgba(255, 255, 255, 0.56); }
    .msg-content__bottom--buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: stretch; }
      .msg-content__bottom--buttons:not(.full-width) {
        width: calc(100% - 100px); }
      .msg-content__bottom--buttons.full-width {
        width: 100%; }
      .msg-content__bottom--buttons .btn {
        background-color: #098CB3 !important;
        border-color: #098CB3 !important; }
        .msg-content__bottom--buttons .btn:hover {
          color: #005872 !important;
          background-color: #27D7FF !important;
          border-color: #27D7FF !important; }
        .msg-content__bottom--buttons .btn:active {
          color: #005C77 !important;
          background-color: #013240 !important;
          border-color: #013240 !important; }
        .msg-content__bottom--buttons .btn:disabled {
          color: #4CA5BA;
          background-color: #034558;
          border: #034558; }
    .msg-content__bottom .btn {
      font: normal normal 400 12px/18px "Montserrat", sans-serif;
      min-width: 190px;
      color: #ffffff;
      margin-left: calc(10px + 0.5vw);
      max-height: 30px;
      padding: 5px calc(0.27vw + 5px);
      box-shadow: none !important;
      border-radius: 2px !important;
      transition: 0.25s; }
      .msg-content__bottom .btn span {
        vertical-align: middle; }
      .msg-content__bottom .btn:disabled {
        cursor: not-allowed; }

/* Right Column */
/* Parsed Response Styles */
.right__col {
  height: 636px;
  position: relative;
  margin: 0;
  padding: 0 !important;
  scrollbar-color: #424242 #1E1E1E;
  /* width */
  /* Track */
  /* Handle */ }
  .right__col ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    border-radius: 0; }
  .right__col ::-webkit-scrollbar-track {
    background: #1E1E1E;
    border-radius: 0; }
  .right__col ::-webkit-scrollbar-thumb {
    background: #424242;
    border-radius: 0; }
  .right__col--result {
    font: normal normal 600 14px/19px "Montserrat", sans-serif;
    color: #ffffff;
    width: 100%;
    padding: 12px 0 12px 24px;
    align-self: flex-start;
    background-color: #1F1F1F;
    position: relative; }
    .right__col--result:before {
      content: "";
      position: absolute;
      display: block;
      height: inherit;
      bottom: 0;
      width: 45%;
      border-bottom: 2px dashed #ffffff; }
  .right__col article {
    width: 100%;
    height: 411px;
    background-color: #1E1E1E;
    margin-top: -1px;
    font: normal normal 400 13px/16px Menlo, Monaco, Consolas, "Courier New", monospace; }
    .right__col article:not(.code__response) {
      overflow-y: auto; }
    .right__col article.code__response.swift-mt__syntax .CodeMirror pre.CodeMirror-line,
    .right__col article.code__response.swift-mt__syntax .CodeMirror pre.CodeMirror-line-like {
      color: #808080 !important; }
    .right__col article > ul {
      padding: 10px 24px !important;
      margin: 0 !important;
      background-color: #1F1F1F !important; }
    .right__col article > .msg-invalid {
      padding: 10px 24px; }
  .right__col .code__response {
    white-space: pre-wrap; }
    .right__col .code__response.msg-valid {
      color: #CCCC99 !important;
      padding: 15px 0 0 15px; }
    .right__col .code__response.msg-invalid {
      padding: 15px 25px; }
    .right__col .code__response--copy {
      font: normal normal 400 14px/1.4 Menlo, Monaco, Consolas, "Courier New", monospace;
      position: absolute;
      right: 24px;
      top: 14px;
      width: 79px;
      background-color: #002834;
      color: #27D7FF;
      border: 1px solid #27D7FF;
      outline: transparent !important;
      box-shadow: none !important;
      padding: 2px 12px;
      border-radius: 2px;
      letter-spacing: 1px;
      transition: 0.45s; }
      .right__col .code__response--copy:focus {
        text-decoration: none !important;
        color: #48DCFF !important;
        background-color: #154C5A !important;
        border-color: #48DCFF !important;
        cursor: pointer; }
      .right__col .code__response--copy:hover {
        text-decoration: none !important;
        color: #154C5A !important;
        background-color: #48DCFF !important;
        cursor: pointer; }
      .right__col .code__response--copy:active {
        text-decoration: none !important;
        color: #48DCFF !important;
        background-color: #013240 !important;
        border-color: #48DCFF !important;
        cursor: pointer; }
    .right__col .code__response--content {
      margin-top: -2px; }
  .right__col--content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #005872;
    height: inherit;
    padding: 0 24px 0 24px;
    width: 100%; }
  .right__col--inner {
    height: calc(100% - 80px);
    margin: 0 auto; }
    .right__col--inner__default {
      max-width: 300px;
      width: calc(70% + 48px); }
    .right__col--inner__result {
      width: calc(100% + 48px);
      margin-left: -24px; }
  .right__col--banner {
    width: 100%;
    height: 100%;
    margin: 10px 0 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch; }
    .right__col--banner__link {
      text-decoration: none !important; }
    .right__col--banner__top {
      display: flex;
      flex-direction: column; }
      .right__col--banner__top-align-right {
        align-items: flex-end;
        text-align: right;
        padding: 20px 0 0 0; }
      .right__col--banner__top-align-left {
        align-items: flex-start;
        text-align: left;
        padding: 20px 0 0 0; }
    .right__col--banner__bubble {
      width: 72px;
      height: 41px;
      background-color: #ffffff;
      border-radius: 28px;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      -webkit-box-shadow: 0 7px 15px rgba(0, 0, 0, 0.16) !important;
      -moz-box-shadow: 0 7px 15px rgba(0, 0, 0, 0.16) !important;
      box-shadow: 0 7px 15px rgba(0, 0, 0, 0.16) !important; }
      .right__col--banner__bubble:before {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        top: -5px; }
      .right__col--banner__bubble--arrow:before {
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        border: 17px solid transparent;
        border-bottom-color: #ffffff;
        border-top: 0;
        margin-top: -10px; }
      .right__col--banner__bubble--arrow__left {
        border-top-left-radius: 0 !important; }
        .right__col--banner__bubble--arrow__left:before {
          border-left: 0;
          left: 0; }
      .right__col--banner__bubble--arrow__right {
        border-top-right-radius: 0 !important;
        margin-left: auto; }
        .right__col--banner__bubble--arrow__right:before {
          border-right: 0;
          right: 0; }
    .right__col--banner__title {
      font: normal normal 500 22px/24px "Montserrat", sans-serif;
      color: #ffffff;
      white-space: pre-wrap;
      margin: 10px 0 0 0; }
    .right__col--banner__caption {
      font: normal normal 500 13px/26px "Montserrat", sans-serif;
      color: #48DCFF; }
    .right__col--banner__bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end; }
      .right__col--banner__bottom p {
        color: #ffffff;
        text-align: right;
        font: normal normal 500 16px/18px "Montserrat", sans-serif;
        max-width: 208px;
        margin-bottom: 8px !important; }
      .right__col--banner__bottom a i {
        position: relative;
        width: 22px;
        height: 22px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: #ffffff;
        margin: 0 8px 0 -8px; }
        .right__col--banner__bottom a i:after {
          position: absolute;
          left: 8px;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 8.7px;
          border-color: transparent transparent transparent #005872; }

.msg-invalid,
.msg-invalid__server-error {
  color: #FF634D;
  font-size: 11px; }

.resp-doc--link {
  color: #FF634D !important;
  text-decoration: underline; }

.msg-validity_text {
  white-space: nowrap; }

.msg-validity_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }

.msg-validity_icon {
  margin-right: 3px; }

.msg-validity_bg {
  top: 0;
  right: 0;
  position: absolute;
  width: 200px;
  height: 51px;
  padding: 10px; }
  .msg-validity_bg.bg-valid .msg-validity_text {
    color: #00DB32; }
  .msg-validity_bg.bg-valid .msg-validity_icon-wrapper {
    background-color: #00DB32; }
  .msg-validity_bg.bg-invalid .msg-validity_text {
    color: #b70000; }
  .msg-validity_bg.bg-invalid .msg-validity_icon-wrapper {
    background-color: #b70000; }

.msg-validity_icon-wrapper {
  width: 33px;
  height: 33px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 0 0 10px; }

.msg-validity_text {
  font: normal normal 600 13px/17px Menlo, Monaco, Consolas, "Courier New", monospace;
  margin-top: 9px;
  margin-left: 12px; }

.msg-validity-animation {
  -webkit-animation: enlarge 0.1s ease-in 1;
  -moz-animation: enlarge 0.1s ease-in 1;
  -o-animation: enlarge 0.1s ease-in 1;
  animation: enlarge 0.1s ease-in 1; }

.logs-charts-tabs {
  margin: 10px 0 0 0;
  border-bottom: 1px solid #545769 !important; }
  .logs-charts-tabs a {
    height: 30px;
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    color: #ffffff !important;
    padding: 5px 33px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font: normal normal 600 13px/22px "Montserrat", sans-serif; }
    .logs-charts-tabs a:nth-child(2) {
      margin: 0 0 0 20px; }
      .logs-charts-tabs a:nth-child(2):after {
        content: 'BETA';
        position: absolute;
        right: 6px;
        top: 4px;
        width: 25px;
        height: 9px;
        line-height: 9px;
        font-size: 7px;
        background-color: #DD1259;
        border-radius: 17px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; }
    .logs-charts-tabs a:not(.active) {
      border: 1px solid #414350 !important;
      background-color: #414350; }
      .logs-charts-tabs a:not(.active):hover {
        border: 1px solid #4B4E5D !important;
        background-color: #4B4E5D;
        opacity: 0.76;
        transition: 0.25s; }
    .logs-charts-tabs a.active {
      border: 1px solid #545769 !important;
      background-color: #545769 !important; }

@media only screen and (max-width: 1140px) {
  .checker {
    min-width: 1140px;
    overflow-x: auto; } }

@media only screen and (max-width: 970px) {
  .checker__container {
    display: none; }
    .checker__container--mobile {
      display: block; }
  .checker__row--false-mobile .left__part,
  .checker__row--false-mobile .middle__part,
  .checker__row--false-mobile .right__part {
    display: none; } }

@media only screen and (max-width: 1620px) {
  .msg-content p {
    flex-wrap: wrap;
    width: 230px;
    padding: 20px 0; }
    .msg-content p span {
      line-height: 1.8; } }

@media screen and (min-width: 971px) {
  .checker__container {
    display: block;
    width: 100%; }
    .checker__container--mobile {
      display: none; }
    .checker__container--false-mobile {
      display: none; } }

@media screen and (min-width: 971px) and (max-width: 1776px) {
  .msg-content__bottom > .btn.clear-min-with-a {
    min-width: 80px !important; }
  .msg-content__bottom > .btn.clear-min-with-b {
    min-width: initial !important; }
  .msg-content__bottom .btn {
    min-width: initial;
    padding: 5px 1.2vw !important;
    line-height: 1.35;
    overflow: hidden;
    white-space: nowrap; }
  .msg-content__bottom--hide-lbl {
    display: none; } }

.auth {
  /* RegisterPage */
  /* LoginPage */
  /* CONFIRMATION PAGE */ }
  .auth__container {
    width: 100%;
    position: relative;
    min-height: calc(100vh - 100px - 240px + 8vh); }
  .auth__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 320px; }
    .auth__form h5 {
      color: #ffffff;
      margin: 4px 0 0 0 !important;
      font: normal normal 400 11px/14px "Montserrat", sans-serif; }
    .auth__form h4 {
      font: normal normal 500 28px/32px "Montserrat", sans-serif; }
      .auth__form h4.auth__login--hdr {
        margin-top: 45px; }
      .auth__form h4.auth__forgot--hdr {
        margin-top: 12px; }
      .auth__form h4.auth__reset--hdr {
        margin-top: 30px; }
    .auth__form p {
      color: #ffffff; }
      .auth__form p.step-1__paragraph {
        margin: 0 !important;
        font: normal normal 300 11px/14px "Montserrat", sans-serif;
        white-space: pre-wrap; }
    .auth__form input[type="text"],
    .auth__form input[type="password"] {
      height: 40px;
      min-width: 320px;
      width: auto;
      background-color: #ffffff;
      padding: 5px 10px;
      border: 0 !important;
      color: #003646;
      margin: 10px 0 28px 0;
      font: normal normal 400 14px/26px "Montserrat", sans-serif;
      /* Change the white to any color ;) */
      filter: none; }
      .auth__form input[type="text"]:focus,
      .auth__form input[type="password"]:focus {
        outline-offset: 0 !important; }
        .auth__form input[type="text"]:focus:not(.auth__form--error),
        .auth__form input[type="password"]:focus:not(.auth__form--error) {
          outline: none !important; }
      .auth__form input[type="text"]::placeholder,
      .auth__form input[type="password"]::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #003646;
        opacity: 1;
        font-style: italic; }
      .auth__form input[type="text"]::-webkit-input-placeholder,
      .auth__form input[type="password"]::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #003646;
        opacity: 1;
        font-style: italic; }
      .auth__form input[type="text"]::-moz-placeholder,
      .auth__form input[type="password"]::-moz-placeholder {
        /* Firefox 19+ */
        color: #003646;
        opacity: 1;
        font-style: italic; }
      .auth__form input[type="text"]:-moz-placeholder,
      .auth__form input[type="password"]:-moz-placeholder {
        /* Firefox 18- */
        color: #003646;
        opacity: 1;
        font-style: italic; }
      .auth__form input[type="text"]:-ms-input-placeholder,
      .auth__form input[type="password"]:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #003646;
        opacity: 1;
        font-style: italic; }
      .auth__form input[type="text"]::-ms-input-placeholder,
      .auth__form input[type="password"]::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #003646;
        opacity: 1;
        font-style: italic; }
      .auth__form input[type="text"]:-webkit-autofill, .auth__form input[type="text"]:-webkit-autofill:hover, .auth__form input[type="text"]:-webkit-autofill:focus, .auth__form input[type="text"]:-webkit-autofill:active,
      .auth__form input[type="password"]:-webkit-autofill,
      .auth__form input[type="password"]:-webkit-autofill:hover,
      .auth__form input[type="password"]:-webkit-autofill:focus,
      .auth__form input[type="password"]:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
        -webkit-text-fill-color: #003646; }
    .auth__form input[name="email"] {
      border-radius: 2px; }
    .auth__form--error {
      outline: 2px solid #DD1259; }
    .auth__form .error-msg-txt {
      position: relative;
      margin: -26px 0 26px -2px;
      left: 0;
      width: 100%;
      color: #ffffff; }
      .auth__form .error-msg-txt i {
        font: normal normal 400 10px/14px "Montserrat", sans-serif;
        font-style: normal !important;
        position: absolute;
        background-color: #DD1259;
        padding: 1px 4px; }
  .auth__pw--tltp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    .auth__pw--tltp .tltp--wrapper {
      position: absolute;
      right: -25px;
      margin-top: -7px; }
      .auth__pw--tltp .tltp--wrapper .popover {
        width: 145px;
        font: normal normal 400 12px/16px "Montserrat", sans-serif;
        color: #272727;
        border: 1px solid #4A8F9F !important;
        top: 5px !important;
        left: 3px !important;
        padding: 2px 2px;
        z-index: 1030 !important; }
        .auth__pw--tltp .tltp--wrapper .popover-body {
          color: #272727 !important; }
        .auth__pw--tltp .tltp--wrapper .popover .arrow {
          margin-left: -1px !important;
          height: 0.6rem !important; }
          .auth__pw--tltp .tltp--wrapper .popover .arrow:before {
            border-bottom-color: #4A8F9F !important;
            border-bottom-width: 10px !important;
            top: -1px !important; }
          .auth__pw--tltp .tltp--wrapper .popover .arrow:after {
            border-bottom-width: 10px; }
  .auth__register {
    width: 100vw; }
    .auth__register.not-fetched article {
      width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start; }
      .auth__register.not-fetched article h3 {
        margin: 0 !important; }
    .auth__register--content {
      position: relative;
      background-image: linear-gradient(to bottom right, #0B6E8B, #92C4D0 77%);
      border-radius: 2px;
      padding: 40px 40px 35px 60px;
      margin: 8vh auto 8vh auto;
      max-width: 730px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch; }
    .auth__register--bottom {
      margin: 0 0 -36px 5px; }
    .auth__register form {
      width: 320px; }
    .auth__register article {
      color: #ffffff; }
      .auth__register article h3 {
        font: normal normal 500 28px/32px "Montserrat", sans-serif; }
      .auth__register article h4 {
        margin: 0 !important;
        font: normal normal 400 14px/26px "Montserrat", sans-serif; }
    .auth__register--terms {
      height: 26px;
      position: relative;
      display: inherit;
      cursor: pointer; }
      .auth__register--terms input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: calc(50% - 10px);
        left: 0;
        width: 12px;
        height: 12px;
        margin: 3px 0 0; }
      .auth__register--terms input[type="checkbox"]:checked + label:before {
        content: "\2713";
        font-size: 11px;
        font-weight: 800; }
      .auth__register--terms label {
        vertical-align: middle;
        font: normal normal 400 11px/11px "Montserrat", sans-serif;
        display: inline-block;
        margin-bottom: 0 !important;
        white-space: nowrap;
        color: #003646; }
        .auth__register--terms label:before {
          content: " ";
          background-color: #ffffff;
          color: #003646;
          width: 12px;
          height: 12px;
          margin: -2px 5px 0 0;
          display: inline-block;
          padding: 1px 0 0 2px;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 2px; }
        .auth__register--terms label:hover {
          cursor: pointer; }
      .auth__register--terms_txt {
        margin-left: 3px; }
      .auth__register--terms__btn {
        background-color: transparent;
        color: #003646;
        border: 0 solid transparent;
        outline: transparent !important;
        font: normal normal 400 11px/11px "Montserrat", sans-serif;
        padding: 0;
        vertical-align: middle;
        text-decoration: underline;
        display: inline-block;
        margin: -1px 0 0 5px;
        white-space: nowrap;
        height: 13px; }
        .auth__register--terms__btn:hover {
          color: #003646 !important;
          opacity: 0.76; }
      .auth__register--terms__from-register {
        margin: 5px 0 2px 0; }
      .auth__register--terms__from-login {
        margin: 16px 0 0 0; }
    .auth__register.fetched .auth__register--content {
      max-width: 645px !important; }
      .auth__register.fetched .auth__register--content article {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        margin: 0 auto;
        width: 100%; }
    .auth__register.fetched .auth__register--verify__left h3 {
      white-space: pre-wrap;
      margin-top: 10px; }
    .auth__register.fetched .auth__register--verify__right {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin: 22px 0 0 70px; }
    .auth__register--email-verification {
      max-width: 645px !important;
      margin: 20px auto auto auto;
      text-align: center; }
    .auth__register--email-sent-to {
      color: #ffffff;
      font: normal normal 400 11px/16px "Montserrat", sans-serif; }
    .auth__register--entered-email {
      font: normal normal 400 16px/26px "Montserrat", sans-serif;
      width: 404px;
      height: 30px;
      position: relative;
      color: #ffffff;
      margin: 0 auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 3px 35px; }
      .auth__register--entered-email span {
        vertical-align: middle;
        display: inline-block; }
    .auth__register--resend-verification {
      width: 404px;
      text-align: center;
      margin: 13px auto 40px auto;
      color: #ffffff;
      white-space: pre-wrap;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      font: normal normal 300 11px/16px "Montserrat", sans-serif; }
    .auth__register--invalid-msg {
      position: absolute;
      top: -2px;
      background-color: #DD1259;
      font: normal normal 400 10px/1 "Montserrat", sans-serif;
      color: #ffffff;
      margin: 15px 0 -10px 0; }
      .auth__register--invalid-msg > span {
        line-height: 13px;
        padding: 0 4px; }
  .auth__login {
    width: 100vw; }
    .auth__login--content {
      position: relative;
      width: auto;
      max-width: fit-content !important;
      margin: 8vh auto;
      background-image: linear-gradient(to bottom right, #0B6E8B, #92C4D0 60%); }
      .auth__login--content article {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: #ffffff;
        padding: 30px 40px 20px 40px; }
        .auth__login--content article h6 {
          font: normal normal 400 14px/18px "Montserrat", sans-serif;
          white-space: pre-wrap; }
  .auth__step-2--paragraph {
    margin: 8px 0 0 0;
    max-width: 320px;
    font: normal normal 400 14px/18px "Montserrat", sans-serif;
    white-space: pre-wrap; }
  .auth__action--fetched__shadow {
    -webkit-animation: float-shadow 6s ease-in 1;
    -moz-animation: float-shadow 6s ease-in 1;
    -o-animation: float-shadow 6s ease-in 1;
    animation: float-shadow 6s ease-in 1;
    margin-top: -100px;
    margin-left: 0;
    opacity: 0.2; }
  .auth__action--fetched__laptop {
    -webkit-animation: float-laptop 6s ease 1;
    -moz-animation: float-laptop 6s ease 1;
    -o-animation: float-laptop 6s ease 1;
    animation: float-laptop 6s ease 1;
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    -ms-transform: translateY(-20px); }
  .auth__submit--btn, .auth__close--btn {
    background-color: #003646;
    height: 40px;
    width: 320px;
    color: #ffffff;
    border: 0 !important;
    border-radius: 2px;
    outline: none !important;
    font: normal normal 400 16px/18px "Montserrat", sans-serif; }
    .auth__submit--btn:disabled, .auth__close--btn:disabled {
      background-color: transparent;
      border: 2px solid #4CA5BA !important;
      color: #4CA5BA;
      cursor: not-allowed; }
  .auth__submit--btn:hover {
    color: #ffffff;
    text-decoration: none; }
  .auth__submit--btn__login {
    margin-top: 9px; }
  .auth__submit--btn__forgot {
    margin: 5px 0 22px 0; }
  .auth__submit--btn__reset {
    margin: 12px 0 22px 0; }
  .auth__submit--btn__finish-reset {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .auth__submit--btn :not(disabled):hover {
    background-color: #27D7FF;
    color: #003646;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.3) !important;
    -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.3) !important;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3) !important; }
  .auth__submit--btn:active {
    background-color: #002631;
    color: #005C77; }
  .auth__reset--btn {
    background-color: transparent;
    color: #ffffff;
    border: 0 solid transparent;
    outline: transparent !important;
    text-decoration: underline;
    cursor: pointer;
    padding: 0 !important; }
    .auth__reset--btn:hover {
      opacity: 0.76; }
    .auth__reset--btn__forgot {
      color: #003646;
      font: normal normal 400 14px/18px "Montserrat", sans-serif;
      position: absolute;
      top: 54px;
      right: -2px; }
      .auth__reset--btn__forgot--wrapper {
        position: relative; }
    .auth__reset--btn__resend {
      color: #ffffff;
      font: normal normal 300 11px/16px "Montserrat", sans-serif; }
  .auth__register--btn {
    margin: 0 6px;
    font: normal normal 400 14px/18px "Montserrat", sans-serif;
    color: #003646;
    text-decoration: underline; }
    .auth__register--btn:hover {
      opacity: 0.76; }
    .auth__register--btn:hover, .auth__register--btn:active, .auth__register--btn:focus {
      color: #003646; }
    .auth__register--btn__container {
      font: normal normal 400 14px/18px "Montserrat", sans-serif;
      color: #003646;
      text-align: center;
      margin: 12px 0 5px 0; }
  .auth__linkedin--btn {
    background-color: transparent;
    color: transparent;
    border: 0 solid transparent;
    outline: transparent !important;
    padding: 0;
    width: 320px;
    height: 40px;
    position: relative; }
    .auth__linkedin--btn img {
      position: absolute;
      left: 0;
      top: 0;
      outline: none;
      image-rendering: -webkit-optimize-contrast;
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -ms-filter: blur(0px);
      filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0);
      /* Chrome, Safari, Opera */
      transform: translateZ(0);
      max-width: 100%;
      background-size: 100%;
      background-image: url("../img/css-sprites-linkedin-buttons.png");
      background-position: 0 50%; }
    .auth__linkedin--btn:active img, .auth__linkedin--btn:focus img, .auth__linkedin--btn:focus:active img {
      background-position: 0 0;
      background-size: 100%; }
    .auth__linkedin--btn:hover img {
      background-position: 0 100%;
      background-size: 100%; }
    .auth__linkedin--btn:disabled {
      cursor: not-allowed; }
      .auth__linkedin--btn:disabled img {
        background-position: 0 50%;
        filter: grayscale(48%);
        opacity: 0.48; }
  .auth__alt {
    position: relative;
    width: 100%;
    height: 30px;
    margin: 14px 0 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .auth__alt span {
      color: #5998a6;
      letter-spacing: 0.5px;
      font: normal normal 500 16px/1.4 "Montserrat", sans-serif; }
      .auth__alt span:before, .auth__alt span:after {
        content: '';
        position: absolute;
        background-color: #5998a6;
        width: calc(50% - 25px);
        height: 1px;
        top: 13px; }
      .auth__alt span:before {
        left: 0; }
      .auth__alt span:after {
        right: 0; }
  .auth__confirm--container {
    width: 100%;
    padding-top: 50px; }
    .auth__confirm--container h1 {
      text-align: center;
      color: #033646;
      font: normal normal 600 36px/36px "Montserrat", sans-serif; }
  .auth__login--top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: -3px; }
  .auth__login--icon {
    position: relative;
    right: 15px;
    margin-left: auto;
    margin-bottom: -7px; }
  .auth__forgot--icon {
    position: relative;
    right: 16px;
    margin: -18px 0 -39px auto; }
  .auth__forgot--fetched {
    width: 645px; }
    .auth__forgot--fetched--content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      margin: 0 auto !important;
      width: calc(100% - 36px);
      padding: 20px 0 10px; }
      .auth__forgot--fetched--content p {
        white-space: pre-wrap; }
    .auth__forgot--fetched__right {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin: 10px 0 0 76px; }
  .auth__reset-fetched--icon {
    position: relative;
    margin: 8px 15px 0 auto; }
  .auth__reset--icon {
    position: relative;
    right: 16px;
    margin: -102px 0 -10px auto; }
  .auth__error--msg {
    text-align: center;
    width: 100%;
    color: #033646;
    margin-top: 120px; }
    .auth__error--msg p {
      font: normal normal 400 19px/1.2 "Montserrat", sans-serif; }
    .auth__error--msg a {
      color: #00b6e1;
      text-decoration: underline; }
  .auth__active-session-msg {
    background-color: rgba(3, 54, 70, 0.96);
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    -ms-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center; }
    .auth__active-session-msg section {
      background-color: #EFEFEF;
      width: auto;
      max-width: 430px;
      height: auto;
      margin: calc(8vh + 40px);
      padding: 30px 25px 5px 25px;
      border-radius: 13px;
      color: #1F1F1F;
      font: normal normal 400 15px/1.4 "Montserrat", sans-serif; }
      .auth__active-session-msg section article {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch; }
    .auth__active-session-msg--fade-out {
      opacity: 0; }
    .auth__active-session-msg--fade-in {
      opacity: 1; }
    .auth__active-session-msg--btns {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      margin: 15px 0 0 0; }
      .auth__active-session-msg--btns button {
        font-size: 14px;
        white-space: nowrap;
        margin: 0 5px 15px 15px;
        min-width: 76px; }
    .auth__active-session-msg--cancel {
      background-color: #ffffff;
      color: #033646;
      border-color: #033646; }
    .auth__active-session-msg--proceed {
      margin: 0 0 0 30px; }

.bg-info_img:hover {
  opacity: 0.76; }

@media only screen and (max-width: 970px) {
  .auth__form {
    max-width: initial;
    width: 100% !important; }
    .auth__form input[type="text"],
    .auth__form input[type="password"] {
      min-width: 100%; }
  .auth__login--content {
    width: calc(100% - 30px); }
    .auth__login--content article {
      padding: 30px 25px 20px 25px; }
  .auth__submit--btn, .auth__close--btn {
    width: 100%; }
  .auth__register.fetched .auth__register--content {
    max-width: initial !important;
    padding: 30px 25px 30px 25px; }
    .auth__register.fetched .auth__register--content article {
      flex-direction: column !important; }
  .auth__register.fetched .auth__register--verify__right {
    margin: 35px auto 0 auto !important; }
  .auth__register:not(.fetched) .auth__register--content {
    padding: 30px 25px 240px 25px; }
  .auth__register--content {
    width: calc(100% - 15px);
    max-width: initial !important;
    flex-direction: column; }
  .auth__register--verify__left {
    width: auto !important;
    white-space: normal !important; }
    .auth__register--verify__left h3 {
      white-space: normal !important; }
  .auth__register--resend-verification, .auth__register--entered-email {
    width: calc(100% - 40px) !important; }
  .auth__register--btn__container {
    text-align: left; }
  .auth__register--bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin: 0 0 0 -96px; }
  .auth__register article {
    width: 100% !important; }
  .auth__linkedin--btn {
    width: 100%; }
  .auth__pw--tltp {
    width: 100%; }
    .auth__pw--tltp .tltp--wrapper {
      right: -20px;
      margin-top: -17px; }
  .auth__forgot--fetched {
    width: auto !important; }
    .auth__forgot--fetched__right {
      margin: 40px auto 0 auto; }
    .auth__forgot--fetched--content {
      flex-direction: column !important;
      width: 100% !important; }
      .auth__forgot--fetched--content p {
        white-space: normal; }
  .auth__reset--btn__forgot--wrapper {
    width: 100%;
    margin: 0 auto; }
  .auth__reset--hdr {
    width: calc(100% - 120px);
    margin: 5px 0 30px 0 !important; }
  .auth__reset--icon {
    right: -8px;
    margin: -108px 0 -10px auto !important; }
  .auth__active-session-msg section {
    width: calc(100% - 40px);
    padding: 20px 20px 5px 20px; } }

.pricing {
  padding-top: 2rem;
  padding-bottom: 5.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch; }
  .pricing__container {
    width: calc(100% - 25vw);
    max-width: 1308px;
    min-width: 961px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16) !important;
    -moz-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16) !important;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16) !important; }
  .pricing article {
    color: #ffffff !important;
    width: calc(34% + 1px);
    padding: 25px 35px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
    .pricing article h3 {
      white-space: nowrap; }
    .pricing article.online-api {
      background-color: #098cb3;
      background-image: linear-gradient(180deg, #098cb3 0, #05465a 100%); }
      .pricing article.online-api .pricing__services h3 {
        margin: 18px 0 0 0; }
    .pricing article.on-premises {
      background-color: #27c3f2;
      background-image: linear-gradient(180deg, #27c3f2 0, #05465a 100%); }
      .pricing article.on-premises:before, .pricing article.on-premises:after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        top: 0; }
      .pricing article.on-premises:before {
        border-left: 1px solid #ffffff;
        left: -0.5px; }
      .pricing article.on-premises:after {
        border-right: 1px solid #ffffff;
        right: -0.5px; }
      .pricing article.on-premises .pricing__services h3 {
        margin: 18px 0 0 0; }
    .pricing article.partnership {
      background: #033646;
      background: linear-gradient(180deg, #033646 0, #002936 100%); }
      .pricing article.partnership .pricing__services h3 {
        margin: 8px 0 23px 0;
        white-space: pre-wrap; }
  .pricing__top--label {
    margin: 4px auto;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
    .pricing__top--label > span {
      font-size: 10px; }
    .pricing__top--label > .bg-sprites + span {
      line-height: 18px; }
    .pricing__top--label > span:only-child {
      line-height: 24px; }
  .pricing hr {
    border-color: #ffffff;
    margin: 3px 0 0 0; }
  .pricing__body {
    max-width: 359px; }
  .pricing__services {
    text-align: center;
    min-height: 168px;
    margin-bottom: 8px; }
    .pricing__services h3 {
      font: normal normal 400 24px/25px "Montserrat", sans-serif; }
  .pricing__info {
    text-align: left;
    margin: 28px 0 33px 0; }
    .pricing__info--item:nth-child(3) {
      margin-top: 18px; }
    .pricing__info--item:nth-child(4) {
      margin-top: 22px; }
    .pricing__info--item:nth-child(6) {
      margin-top: 18px; }
    .pricing__info--item:nth-child(7) {
      margin-top: 28px; }
    .pricing__info--item h4 {
      margin: 17px 0 0 0;
      font: normal normal 600 16px/20px "Montserrat", sans-serif; }
    .pricing__info--item p {
      white-space: pre-wrap;
      margin: 0 0 10px 0;
      font: normal normal 300 16px/20px "Montserrat", sans-serif; }
  .pricing__duration {
    white-space: nowrap;
    font: normal normal 400 16px/20px "Montserrat", sans-serif; }
  .pricing__price {
    margin: 22px 0 5px 0; }
    .pricing__price > span {
      line-height: 26px;
      font-weight: 600; }
    .pricing__price > span:first-child {
      font-size: 30px; }
    .pricing__price > span:nth-child(2) {
      font-size: 24px; }
  .pricing__caption {
    font: normal normal 500 16px/22px "Montserrat", sans-serif; }
  .pricing__prompt {
    margin: 54px 0 32px 0;
    font: normal normal 300 16px/20px "Montserrat", sans-serif; }
  .pricing ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    list-style-position: outside; }
    .pricing ul > li {
      margin: 5px 0 19px 8px;
      font: normal normal 300 16px/20px "Montserrat", sans-serif;
      position: relative; }
      .pricing ul > li:before {
        content: '';
        position: absolute;
        width: 3px;
        height: 3px;
        left: -8px;
        top: 8px;
        background-color: #ffffff;
        border-radius: 100%; }
  .pricing__offer {
    margin: 30px 0 20px 0; }
    .pricing__offer--hdr-img {
      margin: 0; }
      .pricing__offer--hdr-img i {
        margin: 2px 5px 0 0; }
      .pricing__offer--hdr-img span {
        font: normal normal 600 16px/20px "Montserrat", sans-serif; }
    .pricing__offer p {
      font: normal normal 300 16px/20px "Montserrat", sans-serif; }
  .pricing__actions {
    height: 103px;
    width: 100%;
    margin: 28px 0 54px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
    .pricing__actions .pricing__btn {
      font: normal normal 400 12px/18px "Montserrat", sans-serif;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: 0.25s;
      width: 72%;
      min-width: 185px;
      max-width: 225px;
      height: 40px;
      border-radius: 20px;
      text-decoration: none; }
      .pricing__actions .pricing__btn:hover:not(:disabled) {
        color: #003646;
        background-color: #27D7FF;
        border: 2px solid #27D7FF; }
      .pricing__actions .pricing__btn:active:not(:disabled) {
        color: #005C77;
        background-color: #013240;
        border: 2px solid #013240; }
      .pricing__actions .pricing__btn:disabled {
        color: #4CA5BA;
        border-color: #4CA5BA;
        background-color: transparent;
        cursor: not-allowed; }
      .pricing__actions .pricing__btn--free {
        color: #ffffff;
        background-color: transparent;
        border: 2px solid #ffffff; }
      .pricing__actions .pricing__btn--commercial {
        color: #033646;
        background-color: #ffffff;
        border: 2px solid #ffffff; }
    .pricing__actions h4 {
      font: normal normal 600 16px/20px "Montserrat", sans-serif;
      text-align: center;
      white-space: pre-wrap;
      margin: 6px 0; }
  .pricing__tooltip {
    position: absolute;
    bottom: -33px; }
    .pricing__tooltip .tltp--wrapper .tltp {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .pricing__tooltip .tltp--wrapper .tltp i {
        margin: 0 5px 4px 0; }
      .pricing__tooltip .tltp--wrapper .tltp span {
        font: normal normal 400 10px/18px "Montserrat", sans-serif; }
    .pricing__tooltip .tltp--wrapper .popover {
      width: 256px;
      height: 118px;
      padding: 5px;
      -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
      -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important; }
      .pricing__tooltip .tltp--wrapper .popover-body {
        color: #033646;
        font: normal normal 400 10px/14px "Montserrat", sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        height: 100%; }
        .pricing__tooltip .tltp--wrapper .popover-body p {
          margin: 0 0 5px 0; }

@media only screen and (min-width: 721px) and (max-width: 970px) {
  .pricing__container {
    width: calc(100% - 20vw) !important; } }

@media only screen and (max-width: 720px) {
  .pricing__container {
    width: 100% !important; } }

@media only screen and (max-width: 970px) {
  .pricing {
    padding-bottom: 1rem; }
    .pricing__container {
      min-width: initial;
      max-width: initial;
      flex-direction: column;
      box-shadow: none !important; }
    .pricing article {
      width: 100% !important;
      margin-bottom: 20px;
      padding: 20px !important;
      -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16) !important;
      -moz-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16) !important;
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16) !important; }
      .pricing article.on-premises:before, .pricing article.on-premises:after {
        content: none  !important; }
    .pricing__price {
      margin: 9px 0 5px 0; } }

.faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }
  .faq__content {
    color: #033646;
    width: 100%; }
    .faq__content h4 {
      color: #033646;
      text-align: center;
      font: normal normal 400 28px/38px "Montserrat", sans-serif; }
    .faq__content--bottom {
      justify-content: center;
      margin: 0 auto 35px auto !important; }
      .faq__content--bottom a {
        text-decoration: none !important;
        margin-top: 40px; }
    .faq__content a {
      text-decoration: underline;
      color: #066f8f; }
      .faq__content a:hover {
        color: #0894c0; }
  .faq hr {
    height: 1px;
    background-color: #ffffff;
    border: none;
    margin-bottom: 1.5rem !important; }
  .faq__accordion--title {
    cursor: pointer;
    background-color: #EFEFEF;
    width: 100%;
    height: 60px;
    padding: 0 22px 0 4.5vw;
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .faq__accordion--title h5 {
      font: normal normal 400 14px/22px "Montserrat", sans-serif;
      margin: 0;
      max-width: calc(100% - 33px); }
    .faq__accordion--title:hover .faq__accordion--title__symbol span {
      background-color: #066f8f; }
    .faq__accordion--title__symbol {
      height: 20px;
      width: 20px;
      position: relative; }
      .faq__accordion--title__symbol.closed .vertical {
        transition: all 0.5s ease-in-out;
        transform: rotate(-90deg); }
      .faq__accordion--title__symbol.closed .horizontal {
        transition: all 0.5s ease-in-out;
        transform: rotate(-90deg);
        opacity: 1; }
      .faq__accordion--title__symbol.opened {
        opacity: 1; }
        .faq__accordion--title__symbol.opened .vertical {
          transition: all 0.5s ease-in-out;
          transform: rotate(90deg); }
        .faq__accordion--title__symbol.opened .horizontal {
          transition: all 0.5s ease-in-out;
          transform: rotate(90deg);
          opacity: 0; }
      .faq__accordion--title__symbol .horizontal {
        position: absolute;
        background-color: #033646;
        width: 18px;
        height: 2px;
        left: 50%;
        margin-left: -9px;
        top: 50%;
        margin-top: -1px; }
      .faq__accordion--title__symbol .vertical {
        position: absolute;
        background-color: #033646;
        width: 2px;
        height: 18px;
        left: 50%;
        margin-left: -1px;
        top: 50%;
        margin-top: -9px; }
  .faq__accordion--text {
    padding: 0 60px 20px 5vw;
    background-color: #EFEFEF;
    margin-top: -4px;
    margin-bottom: 4px;
    font: normal normal 400 12px/20px "Montserrat", sans-serif; }

@media only screen and (max-width: 970px) {
  .faq__accordion--title {
    padding: 0 22px; } }

.fin-messages {
  width: 100%; }
  .fin-messages__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 0 auto; }
  .fin-messages__block {
    width: 100%;
    height: initial; }
  .fin-messages__key-benefits {
    background-color: #033646;
    color: #ffffff;
    padding: 58px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .fin-messages__key-benefits--container {
      width: calc(100% - 24vw);
      max-width: 1066px;
      padding: 0;
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch; }
      .fin-messages__key-benefits--container article {
        width: calc(50% - 18px);
        margin: 25px 0 12px 0;
        padding: 0 6px; }
        .fin-messages__key-benefits--container article h3 {
          font: normal normal 500 20px/22px "Montserrat", sans-serif; }
        .fin-messages__key-benefits--container article p {
          font: normal normal 300 16px/24px "Montserrat", sans-serif; }
  .fin-messages__libraries article {
    padding: 40px 20px 20px 20px; }
  .fin-messages__libraries h4 {
    font: normal normal 700 28px/32px "Montserrat", sans-serif;
    margin-bottom: 5px; }
  .fin-messages__libraries p {
    font: normal italic 500 16px/24px "Montserrat", sans-serif;
    max-width: calc(50% + 125px);
    margin: 5px auto 0 auto; }
  .fin-messages__msg-types {
    background-color: #EFEFEF;
    width: 100%;
    padding: 33px 0 0 0; }
    .fin-messages__msg-types--content {
      width: calc(100% - 24vw);
      max-width: 800px;
      color: #033646;
      margin: 0 auto 0 auto;
      padding: 0 20px; }
      .fin-messages__msg-types--content article {
        width: calc(100% - 15px); }
    .fin-messages__msg-types--specifications {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
      margin: 10px 0 0 0; }
    .fin-messages__msg-types--item {
      padding-bottom: 20px;
      position: relative;
      height: 127px; }
      .fin-messages__msg-types--item.full-height {
        max-height: initial;
        height: initial; }
      .fin-messages__msg-types--item.min-height {
        height: 127px;
        overflow: hidden; }
      .fin-messages__msg-types--item h5 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        padding: 25px 20px 0 0;
        margin: 0 0 2px 0;
        width: calc(100% + 20px); }
        .fin-messages__msg-types--item h5 > span:first-child {
          font: normal normal 600 16px/22px "Montserrat", sans-serif; }
        .fin-messages__msg-types--item h5 > span:nth-child(2) {
          font: normal normal 300 13px/15px "Montserrat", sans-serif;
          white-space: nowrap; }
          .fin-messages__msg-types--item h5 > span:nth-child(2).coming-soon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center; }
            .fin-messages__msg-types--item h5 > span:nth-child(2).coming-soon .bg-github {
              margin-left: 5px; }
            .fin-messages__msg-types--item h5 > span:nth-child(2).coming-soon:hover {
              opacity: 0.76; }
          .fin-messages__msg-types--item h5 > span:nth-child(2) a:hover {
            opacity: 0.76; }
          .fin-messages__msg-types--item h5 > span:nth-child(2) a i {
            display: inline-block;
            margin: 3px 0 -2px 3px; }
    .fin-messages__msg-types--paragraphs {
      font: normal normal 300 13px/20px "Montserrat", sans-serif;
      max-width: calc(100% - 8px); }
      .fin-messages__msg-types--paragraphs p {
        margin: 0; }
    .fin-messages__msg-types--toggle__wrapper {
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: 0;
      background-color: #EFEFEF; }
    .fin-messages__msg-types--toggle__txt-btn {
      border: none;
      color: #033646;
      text-align: left;
      background-color: transparent;
      margin: 0;
      padding: 0;
      font: normal normal 600 12px/22px "Montserrat", sans-serif; }
      .fin-messages__msg-types--toggle__txt-btn:hover {
        opacity: 0.8; }
    .fin-messages__msg-types--samples {
      margin: 30px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .fin-messages__msg-types--samples__sdks {
        transition: 0.25s;
        color: #ffffff;
        font: normal normal 400 14px/1 "Montserrat", sans-serif;
        background-color: #3D8394;
        padding: 10px 15px;
        border-radius: 16px; }
        .fin-messages__msg-types--samples__sdks:hover {
          background-color: #27D7FF;
          text-decoration: none; }
      .fin-messages__msg-types--samples__apis {
        transition: 0.25s;
        margin: 12px 0 0 0;
        display: inline-block; }
        .fin-messages__msg-types--samples__apis:hover {
          opacity: 0.76;
          text-decoration: none; }
        .fin-messages__msg-types--samples__apis span {
          color: #3D8394;
          font: normal normal 400 14px/24px "Montserrat", sans-serif; }
        .fin-messages__msg-types--samples__apis i {
          border: solid #3D8394;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          margin: 0 0 1px 3px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg); }
  .fin-messages__features {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 76px 20px 58px 20px; }
    .fin-messages__features--container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: calc(100% - 24vw);
      max-width: 758px;
      padding: 0 10px 0 0; }
    .fin-messages__features h6 {
      font: normal normal 600 16px/22px "Montserrat", sans-serif;
      color: #033646; }
    .fin-messages__features p {
      font: normal normal 300 13px/20px "Montserrat", sans-serif;
      color: #033646; }
  .fin-messages__licensing {
    width: calc(64% - 10px);
    margin: 0 auto 0 0;
    background-color: #E2E2E2;
    border-radius: 21px;
    padding: 20px 25px; }
    .fin-messages__licensing p {
      margin: 13px 0 0 0; }
  .fin-messages__offer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: calc(36% - 10px);
    margin: 0 0 0 auto;
    background-color: #033646;
    border-radius: 21px;
    padding: 20px;
    text-decoration: none !important; }
    .fin-messages__offer p {
      margin: 26px 0 0 0;
      color: #ffffff; }
    .fin-messages__offer--hdr-img {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .fin-messages__offer--hdr-img i {
        margin: 1px 5px 0 0; }
      .fin-messages__offer--hdr-img span {
        margin: 2px 0 -3px 4px;
        color: #ffffff; }
  .fin-messages__quote article {
    max-width: 500px; }
    .fin-messages__quote article a {
      color: #033646 !important;
      background-color: #ffffff; }
      .fin-messages__quote article a:hover {
        background-color: #27D7FF; }
  .fin-messages__quote--a article {
    padding: 20px !important; }
    .fin-messages__quote--a article a {
      margin-bottom: 40px; }
      .fin-messages__quote--a article a:nth-child(2) {
        margin-left: 40px; }
  .fin-messages__quote--b h4 {
    margin: 45px 0 5px 0; }
  .fin-messages__quote--b article {
    padding: 20px 20px 42px 20px !important; }
    .fin-messages__quote--b article a {
      margin-bottom: 12px; }
  .fin-messages__faq h4 {
    margin: 60px 0 5px 0; }
  .fin-messages__faq .faq__content {
    max-width: 762px;
    margin: 0 auto; }
  .fin-messages__question {
    width: 100%;
    height: 100px; }
    .fin-messages__question article {
      max-width: 762px;
      margin: 35px auto; }

@media only screen and (max-width: 1095px) {
  .fin-messages__content {
    flex-wrap: nowrap; }
  .fin-messages__block {
    width: 100%; }
  .fin-messages__libraries h4, .fin-messages__quote h4 {
    margin: 0 !important;
    line-height: 1.2; }
  .fin-messages__quote--a article a {
    margin-bottom: 20px; }
  .fin-messages__quote--b article {
    padding: 30px 10px !important; }
  .fin-messages__libraries p {
    max-width: calc(100% - 40px);
    margin: 10px auto 0 auto; }
  .fin-messages__key-benefits {
    padding: 20px; }
    .fin-messages__key-benefits--container {
      width: 100%;
      padding: 0; }
      .fin-messages__key-benefits--container article {
        width: calc(100% - 10px);
        margin: 15px 0 0 0; }
  .fin-messages__msg-types {
    position: static;
    width: 100%;
    flex: initial;
    padding: 20px 0; }
    .fin-messages__msg-types--content {
      width: 100%;
      margin: auto;
      padding: 0; }
      .fin-messages__msg-types--content article {
        width: initial;
        margin: 0 auto;
        padding: 0 20px; }
    .fin-messages__msg-types--item .fin-messages__msg-types--toggle__wrapper {
      height: 25px !important; }
    .fin-messages__msg-types--item h5 {
      width: 100%; }
      .fin-messages__msg-types--item h5 span:first-child {
        line-height: 16px; }
    .fin-messages__msg-types--paragraphs {
      font-size: 15px; }
      .fin-messages__msg-types--paragraphs--toggle__wrapper {
        height: 22px; }
  .fin-messages__features {
    padding: 15px 20px 35px 20px; }
    .fin-messages__features--container {
      padding: 0;
      flex-direction: column; }
      .fin-messages__features--container > article {
        width: 100%; }
  .fin-messages__offer {
    margin: 25px 0 0 0;
    width: 100%; }
    .fin-messages__offer p {
      margin: 10px 0 0 0; }
  .fin-messages__faq h4 {
    margin: 40px 0 5px 0; }
  .fin-messages__faq h5 {
    margin: 35px 0 5px 0; }
  .fin-messages__question {
    height: 112px; }
    .fin-messages__question article {
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .fin-messages__question article a {
        padding: 11px 22px;
        font-size: 16px;
        border-radius: 22px;
        width: auto;
        height: auto; }
    .fin-messages__question article {
      margin: 22px auto; } }

.resources {
  padding-top: 2rem;
  width: calc(100% - 30vw);
  max-width: 730px;
  margin: 0 auto;
  padding-bottom: 40px; }
  .resources__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 0 0 5vw; }
  .resources__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(50% - 20px); }
    .resources__column h4 {
      font: normal normal 600 16px/22px "Montserrat", sans-serif;
      color: #033646;
      margin: 30px 0 5px 0; }
    .resources__column p {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch; }
      .resources__column p a {
        font: normal normal 400 13px/18px "Montserrat", sans-serif;
        color: #3D8394;
        margin: 8px 0;
        text-decoration: underline;
        text-decoration-color: #3D8394; }

.release-notes {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding-top: 2rem;
  width: calc(100% - 25vw);
  margin: 0 auto;
  padding-bottom: 40px;
  min-height: calc(100vh - 100px - 240px); }
  .release-notes article {
    width: 100%; }
  .release-notes h4,
  .release-notes h5 {
    color: #033646; }
  .release-notes h4 {
    font: normal normal 600 20px/20px "Montserrat", sans-serif; }
  .release-notes h5 {
    font: normal normal 500 14px/20px "Montserrat", sans-serif; }
  .release-notes ul {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    list-style-position: outside;
    margin: 5px 0 15px 20px; }
    .release-notes ul p * {
      font: normal normal 400 14px/20px "Montserrat", sans-serif;
      color: #1E1E1E; }
  .release-notes__error {
    color: #B90041; }

@media screen and (max-width: 970px) {
  .resources {
    width: calc(100% - 40px); }
    .resources__container {
      flex-direction: column;
      align-items: center;
      margin: 0; }
    .resources__column {
      width: calc(100% - 20px); }
      .resources__column article {
        width: 100%;
        margin: 0 auto; }
        .resources__column article p a {
          word-break: break-word; }
  .release-notes {
    min-height: calc(100vh - 85px - 240px) !important; } }

.purchase-req {
  width: calc(100% - 33vw);
  min-width: 980px;
  height: auto;
  background-color: #ffffff;
  margin: 6vw auto;
  padding: 70px 85px 40px 95px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; }
  .purchase-req__col--left-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 75px;
    padding-bottom: 10px;
    position: relative; }
  .purchase-req__col--right-wrapper {
    display: inline-block;
    width: 45.6vw;
    max-width: 695px; }
  .purchase-req__col h3 {
    font: normal normal 600 36px/1.2 "Montserrat", sans-serif;
    margin-bottom: 20px;
    color: #033646; }
  .purchase-req__col h4 {
    font: normal normal 600 16px/22px "Montserrat", sans-serif;
    margin-bottom: 2px;
    color: #033646; }
    .purchase-req__col h4.msg-types {
      margin-top: 24px; }
    .purchase-req__col h4.about {
      margin-top: 20px; }
  .purchase-req__col p {
    font: normal normal 300 14px/22px "Montserrat", sans-serif;
    white-space: pre-wrap; }
  .purchase-req__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch; }
    .purchase-req__left .banner {
      background-color: #000000;
      border-radius: 9px;
      width: 300px;
      height: 90px;
      margin: 30px 0 55px 0;
      position: relative; }
      .purchase-req__left .banner__text {
        font: normal normal 500 12px/14px "Montserrat", sans-serif;
        max-width: 176px;
        color: #ffffff;
        position: absolute;
        padding: 19px 15px; }
        .purchase-req__left .banner__text span {
          font: normal normal 500 18px/1 "Montserrat", sans-serif; }
      .purchase-req__left .banner__checkmarks {
        top: 10px;
        right: 28px;
        position: absolute;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: flex-start; }
        .purchase-req__left .banner__checkmarks .checkmark {
          display: inline-block;
          width: 22px;
          height: 22px;
          transform: rotate(38deg);
          -webkit-transform: rotate(38deg);
          -moz-transform: rotate(38deg);
          -o-transform: rotate(38deg);
          -ms-transform: rotate(38deg); }
          .purchase-req__left .banner__checkmarks .checkmark:first-child {
            margin-left: 12px; }
        .purchase-req__left .banner__checkmarks .checkmark_stem {
          background-color: #000000;
          position: absolute;
          width: 9px;
          height: 80px;
          top: 1px;
          right: -7px;
          z-index: 2;
          display: block; }
          .purchase-req__left .banner__checkmarks .checkmark_stem:before {
            content: "";
            background-color: #000000;
            position: absolute;
            width: 10px;
            height: 73px;
            top: -6px;
            right: 17px; }
          .purchase-req__left .banner__checkmarks .checkmark_stem:after {
            content: "";
            background-color: #104F62;
            position: absolute;
            width: 10px;
            height: 73px;
            top: 0;
            right: 9px; }
        .purchase-req__left .banner__checkmarks .checkmark_kick {
          background-color: #104F62;
          position: absolute;
          width: 45px;
          height: 9px;
          top: 68px;
          left: -20px;
          transform: rotate(9deg);
          -webkit-transform: rotate(9deg);
          -moz-transform: rotate(9deg);
          -o-transform: rotate(9deg);
          -ms-transform: rotate(9deg); }
  .purchase-req__title {
    margin: 0 0 15px 0; }
  .purchase-req__info {
    width: calc(90% - 20px);
    min-width: 300px;
    margin: 28px 0 0 0; }
  .purchase-req .logo {
    background-color: #033646;
    padding: 20px;
    width: 300px;
    height: 90px;
    border-radius: 9px; }
    .purchase-req .logo .branding__paymentcomponents {
      margin: 15px 0 0 56px !important; }
  .purchase-req__mail--btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 5px 0;
    width: calc(90% - 20px);
    min-width: 300px; }
    .purchase-req__mail--btns > article > a {
      width: 220px;
      margin: 20px 0 0 0;
      white-space: nowrap; }
      .purchase-req__mail--btns > article > a:hover {
        background-color: #EFEFEF !important; }

.button__back {
  top: calc(4rem + 100px);
  position: absolute;
  left: 50px;
  width: 88px;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none !important; }
  .button__back i {
    margin: 0 12px 3px 0; }
  .button__back span {
    font: normal normal 500 16px/26px Montserrat,sans-serif; }
  .button__back:hover {
    opacity: .76;
    color: #fff !important; }

@media screen and (max-width: 1300px) {
  .purchase-req {
    padding: 40px;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    min-width: auto; }
    .purchase-req__left {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 10px; }
      .purchase-req__left .logo,
      .purchase-req__left .banner {
        margin: 20px 20px 0 0; }
    .purchase-req__col {
      height: auto; }
      .purchase-req__col--left-wrapper {
        align-items: flex-start;
        flex-direction: column-reverse;
        margin-top: 15px; }
      .purchase-req__col--right-wrapper {
        flex-direction: row;
        width: 100%;
        margin-right: auto; }
    .purchase-req__info {
      width: auto; } }

@media screen and (max-width: 970px) {
  .purchase-req {
    padding: 30px;
    width: calc(100% - 40px);
    margin: 70px auto 40px auto; }
    .purchase-req__col {
      width: 100%; }
    .purchase-req__info {
      min-width: 100%;
      margin: 20px 0 0 0; }
    .purchase-req__mail--btns {
      flex-wrap: wrap; }
  .button__back {
    top: calc(20px + 85px);
    left: 10px; } }

@media screen and (max-width: 696px) {
  .purchase-req__title h3 {
    font-size: 28px !important; }
  .purchase-req__mail--btns {
    flex-direction: column;
    align-items: flex-start; } }

.email-comment {
  width: 100%;
  margin: 20px 0 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .email-comment input {
    font: normal normal 400 12px/18px "Montserrat", sans-serif;
    color: #92C4D0;
    width: 100%;
    height: 35px;
    border: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    padding: 5px 10px;
    outline: none !important;
    background-color: #242931; }
    .email-comment input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #92C4D0;
      opacity: 1;
      font-style: italic; }
    .email-comment input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #92C4D0;
      opacity: 1;
      font-style: italic; }
    .email-comment input::-moz-placeholder {
      /* Firefox 19+ */
      color: #92C4D0;
      opacity: 1;
      font-style: italic; }
    .email-comment input:-moz-placeholder {
      /* Firefox 18- */
      color: #92C4D0;
      opacity: 1;
      font-style: italic; }
    .email-comment input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #92C4D0;
      opacity: 1;
      font-style: italic; }
    .email-comment input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #92C4D0;
      opacity: 1;
      font-style: italic; }
    .email-comment input.success::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #3BDAFF;
      opacity: 1;
      font-style: italic; }
    .email-comment input.success::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #3BDAFF;
      opacity: 1;
      font-style: italic; }
    .email-comment input.success::-moz-placeholder {
      /* Firefox 19+ */
      color: #3BDAFF;
      opacity: 1;
      font-style: italic; }
    .email-comment input.success:-moz-placeholder {
      /* Firefox 18- */
      color: #3BDAFF;
      opacity: 1;
      font-style: italic; }
    .email-comment input.success:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #3BDAFF;
      opacity: 1;
      font-style: italic; }
    .email-comment input.success::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #3BDAFF;
      opacity: 1;
      font-style: italic; }
    .email-comment input.fail::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #FF4A4A;
      opacity: 1;
      font-style: italic; }
    .email-comment input.fail::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #FF4A4A;
      opacity: 1;
      font-style: italic; }
    .email-comment input.fail::-moz-placeholder {
      /* Firefox 19+ */
      color: #FF4A4A;
      opacity: 1;
      font-style: italic; }
    .email-comment input.fail:-moz-placeholder {
      /* Firefox 18- */
      color: #FF4A4A;
      opacity: 1;
      font-style: italic; }
    .email-comment input.fail:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #FF4A4A;
      opacity: 1;
      font-style: italic; }
    .email-comment input.fail::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #FF4A4A;
      opacity: 1;
      font-style: italic; }
  .email-comment button {
    font: normal normal 400 12px/17px "Montserrat", sans-serif;
    cursor: pointer;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border: 0;
    width: 62px;
    height: 35px;
    outline: none !important; }
    .email-comment button.after-send {
      color: #ffffff;
      background-color: #005872;
      font-size: 10px;
      cursor: default; }
      .email-comment button.after-send.fail {
        font-size: 20px;
        font-weight: 500; }
    .email-comment button.before-send {
      color: #ffffff;
      background-color: #026D8D; }
      .email-comment button.before-send:hover:not(:disabled) {
        -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.3) !important;
        -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.3) !important;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.3) !important;
        background-color: #27D7FF; }
      .email-comment button.before-send:disabled {
        opacity: 0.76;
        cursor: not-allowed;
        filter: grayscale(0.6); }
  .email-comment__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end; }

aside {
  display: block;
  min-width: 215px; }
  aside nav {
    width: 100%;
    height: 636px;
    background-color: #033646;
    position: relative;
    padding: 10px 15px; }
    aside nav .aside-nav__content {
      width: 240px;
      margin: 0 auto; }

.search-msg {
  padding: 10px 0 5px 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .search-msg__box {
    width: 225px;
    height: 27px; }
  .search-msg__control {
    height: 27px;
    min-height: 27px !important;
    background-color: #000000 !important;
    border-color: #000000 !important;
    cursor: pointer !important; }
    .search-msg__control--is-focused {
      box-shadow: 0 0 0 1px #48DCFF !important; }
  .search-msg__placeholder {
    font: normal italic 400 11px/18px "Montserrat", sans-serif;
    color: #48DCFF !important;
    margin: 0 !important;
    height: 27px;
    word-spacing: 2px !important;
    letter-spacing: -0.4px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .search-msg__placeholder + div {
      height: 27px;
      margin: 0 0 0 0 !important;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
  .search-msg__value-container {
    width: 225px;
    height: 27px;
    padding: 5px 5px !important;
    outline: none !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .search-msg__value-container > div {
      height: 27px;
      margin: 0 0 0 0 !important;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
  .search-msg__single-value {
    font: normal normal 400 11px/18px "Montserrat", sans-serif;
    color: #48DCFF !important; }
  .search-msg__input {
    font: normal normal 400 11px/18px "Montserrat", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #48DCFF !important;
    height: 27px; }
  .search-msg__indicator {
    padding: 0 !important;
    margin: 0 !important;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 27px; }
    .search-msg__indicator-separator {
      display: none !important; }
  .search-msg__menu {
    background-color: #000000 !important;
    margin-top: 1px !important;
    padding-top: 0 !important;
    scrollbar-color: #2C2C2C #000000;
    /* width */
    /* Track */
    /* Handle */ }
    .search-msg__menu ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      border-radius: 0; }
    .search-msg__menu ::-webkit-scrollbar-track {
      background: #000000;
      border-radius: 0; }
    .search-msg__menu ::-webkit-scrollbar-thumb {
      background: #2C2C2C;
      border-radius: 0; }
    .search-msg__menu-list {
      padding-top: 0 !important; }
    .search-msg__menu-notice {
      color: #48DCFF !important; }
  .search-msg__option {
    font-size: 11px !important;
    color: #48DCFF !important;
    cursor: pointer !important; }
    .search-msg__option--is-focused {
      background-color: #48DCFF !important;
      color: #272727 !important; }
    .search-msg__option--is-selected {
      background-color: #034558 !important;
      color: #48DCFF !important; }
      .search-msg__option--is-selected:hover {
        color: #48DCFF !important; }

.tree {
  width: inherit;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  scrollbar-color: #154C5A #002834;
  /* width */
  /* Track */
  /* Handle */
  scrollbar-height: 28px; }
  .tree ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    border-radius: 0; }
  .tree ::-webkit-scrollbar-track {
    background: #002834;
    border-radius: 0; }
  .tree ::-webkit-scrollbar-thumb {
    background: #154C5A;
    border-radius: 0; }
  .tree ::-webkit-scrollbar-thumb {
    height: 28px !important; }
  .tree__title {
    font: normal normal 500 16px/1.2 "Montserrat", sans-serif;
    margin: 15px 0 0 10px; }
  .tree a {
    color: #ffffff;
    text-decoration: none !important; }
    .tree a:hover {
      color: white; }
  .tree span.tree__bot-level {
    color: #ffffff;
    opacity: 0.5 !important;
    cursor: initial !important;
    text-decoration: none !important; }
  .tree__top-level {
    font: normal normal 500 12px/35px "Montserrat", sans-serif;
    color: #ffffff !important;
    text-decoration: none !important;
    padding: 0 0 0 10px; }
  .tree__mid-level {
    font: normal normal 500 10px/18px "Montserrat", sans-serif;
    cursor: pointer;
    height: 23px; }
    .tree__mid-level--wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      max-height: 160px;
      margin-top: -5px;
      padding: 5px 20px 5px 25px;
      overflow-y: auto;
      background-color: #002F3E; }
  .tree__bot-level {
    height: 21px;
    font: normal normal 400 10px/21px "Montserrat", sans-serif;
    color: #ffffff;
    text-decoration: none !important;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    vertical-align: middle; }
    .tree__bot-level--wrapper {
      padding: 0 25px; }
    .tree__bot-level--item {
      height: 34px;
      margin-top: -10px;
      line-height: 33px; }
    .tree__bot-level--active {
      color: #27D7FF !important; }
  .tree__icon {
    display: inline-block;
    margin-right: 5px; }
  .tree > .treeview > div {
    background-color: #00485D;
    max-height: 215px !important;
    overflow-y: auto !important; }
  .tree .treeview {
    color: #ffffff;
    cursor: pointer;
    padding: 0 0 0 12px !important; }
    .tree .treeview svg {
      fill: #ffffff;
      width: 9px !important;
      height: 9px !important;
      margin: 0 5px 0 0 !important; }
    .tree .treeview > div {
      border: 0 !important;
      padding: 2px 0 0 13px !important;
      margin: 0 0 0 -12px !important; }
    .tree .treeview .treeview {
      margin-top: -4px; }
      .tree .treeview .treeview > div {
        padding: 0 0 0 34px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch; }

@media only screen and (max-width: 1205px) {
  .trans-span {
    display: none; }
  .search-msg__box {
    width: calc(225px - 42px); }
  aside nav > .aside-nav__content {
    width: calc(215px); } }

.checker__charts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0 0 50px 0; }
  .checker__charts--finaplo-ai {
    height: 52px;
    width: 100%;
    background-color: #545769;
    margin: 0;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center; }
  .checker__charts--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    min-width: 300px; }
    .checker__charts--wrapper__left {
      width: calc(55% - 5px); }
    .checker__charts--wrapper__right {
      width: calc(45% - 5px); }
  .checker__charts--item {
    height: 260px;
    width: 100%;
    margin: 28px 0 30px 0; }
    .checker__charts--item h4 {
      color: #ffffff;
      margin: 5px 0 25px 16px;
      font: normal normal 400 14px/1.2 "Montserrat", sans-serif; }
  .checker__charts--type-currency > div:not(.checker__charts--no-data):not(.checker__charts--error) {
    margin: -10px 0 10px 0; }
  .checker__charts--no-data {
    color: #27D7FF;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .checker__charts--error {
    color: #f03a17;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .checker__charts--loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 260px; }
    .checker__charts--loading h5 {
      font-size: 16px;
      color: #27D7FF; }

@media screen and (max-width: 1367px) {
  .checker__charts--item h4 {
    text-align: center; }
  .checker__charts--wrapper {
    width: 100% !important; } }

.checker__logs {
  padding: 0 0 5px 0; }
  .checker__logs .custom-pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font: normal normal 400 13px/1.2 "Montserrat", sans-serif;
    margin: 10px 0 15px 0;
    color: #ffffff; }
    .checker__logs .custom-pagination__rows-per-page {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .checker__logs .custom-pagination__rows-per-page #rows-per-page {
        padding: 5px;
        border: 1px solid #787c96;
        background-color: #787c96;
        line-height: 1.2;
        margin: 0 0 0 8px;
        border-radius: 3px;
        height: 28px;
        color: #ffffff;
        font: normal normal 400 13px/1.2 "Montserrat", sans-serif; }
        .checker__logs .custom-pagination__rows-per-page #rows-per-page + div {
          min-width: 36px !important;
          padding: 0;
          background-color: #787c96; }
          .checker__logs .custom-pagination__rows-per-page #rows-per-page + div a {
            font: normal normal 400 13px/1.2 "Montserrat", sans-serif;
            padding: 5px 0 5px 5px;
            width: 36px !important;
            color: #ffffff; }
            .checker__logs .custom-pagination__rows-per-page #rows-per-page + div a:hover {
              color: #333542; }
        .checker__logs .custom-pagination__rows-per-page #rows-per-page:hover, .checker__logs .custom-pagination__rows-per-page #rows-per-page:active, .checker__logs .custom-pagination__rows-per-page #rows-per-page:focus {
          background-color: #787c96;
          box-shadow: none; }
    .checker__logs .custom-pagination__paginator {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .checker__logs .custom-pagination__paginator--content {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap; }
        .checker__logs .custom-pagination__paginator--content > button {
          font: normal normal 500 13px/1.2 "Montserrat", sans-serif; }
          .checker__logs .custom-pagination__paginator--content > button:disabled {
            cursor: not-allowed; }
            .checker__logs .custom-pagination__paginator--content > button:disabled i {
              border-color: #808080;
              opacity: 0.76; }
    .checker__logs .custom-pagination__btn {
      background-color: transparent !important;
      border: 0;
      color: #27D7FF;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .checker__logs .custom-pagination__btn.btn-primary {
        color: #00738d;
        font-weight: 600 !important; }
      .checker__logs .custom-pagination__btn > i {
        border: solid #27D7FF;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px; }
      .checker__logs .custom-pagination__btn:hover > span {
        color: #00738d; }
      .checker__logs .custom-pagination__btn:hover > i {
        border-color: #005C77; }
      .checker__logs .custom-pagination__btn:focus {
        box-shadow: none; }
      .checker__logs .custom-pagination__btn:active:focus {
        color: #005C77;
        outline: none;
        box-shadow: none !important; }
    .checker__logs .custom-pagination__dots {
      white-space: nowrap;
      width: 40px;
      font: normal normal 600 15px/22px "Montserrat", sans-serif;
      color: #098CB3;
      text-align: center;
      margin: 0 3px; }
    .checker__logs .custom-pagination__first i, .checker__logs .custom-pagination__previous i {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg); }
    .checker__logs .custom-pagination__next i, .checker__logs .custom-pagination__last i {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg); }
    .checker__logs .custom-pagination__pages {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .checker__logs .custom-pagination__pages > span {
        margin-right: 30px; }
      .checker__logs .custom-pagination__pages--jump-to {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center; }
        .checker__logs .custom-pagination__pages--jump-to > span {
          margin-right: 8px; }
        .checker__logs .custom-pagination__pages--jump-to > div {
          width: 55px;
          box-shadow: none; }
          .checker__logs .custom-pagination__pages--jump-to > div > div {
            background-color: #787c96;
            width: 55px;
            min-height: 28px;
            cursor: pointer;
            border-color: #787c96 !important;
            box-shadow: none !important;
            margin-top: 3px !important; }
            .checker__logs .custom-pagination__pages--jump-to > div > div div[class$="-placeholder"],
            .checker__logs .custom-pagination__pages--jump-to > div > div div[class$="-indicatorContainer"] {
              color: silver; }
            .checker__logs .custom-pagination__pages--jump-to > div > div div[class$="1n7v3ny-option"] {
              color: #787c96 !important; }
            .checker__logs .custom-pagination__pages--jump-to > div > div div[class$="-Input"] {
              color: #ffffff !important; }
            .checker__logs .custom-pagination__pages--jump-to > div > div[class$="-menu"] {
              border: 1px solid #787c96;
              overflow: hidden; }
              .checker__logs .custom-pagination__pages--jump-to > div > div[class$="-menu"] > div {
                overflow-x: hidden;
                margin-right: -17px; }
                .checker__logs .custom-pagination__pages--jump-to > div > div[class$="-menu"] > div > div {
                  cursor: pointer; }
                  .checker__logs .custom-pagination__pages--jump-to > div > div[class$="-menu"] > div > div:hover {
                    color: #787c96; }
            .checker__logs .custom-pagination__pages--jump-to > div > div > div:nth-child(2) {
              padding: 0 2px 0 0; }
              .checker__logs .custom-pagination__pages--jump-to > div > div > div:nth-child(2) > span {
                display: none; }
              .checker__logs .custom-pagination__pages--jump-to > div > div > div:nth-child(2) > div {
                cursor: pointer;
                padding: 0; }
                .checker__logs .custom-pagination__pages--jump-to > div > div > div:nth-child(2) > div > svg {
                  width: 14px !important;
                  border: 0; }
  .checker__logs .react-bootstrap-table table {
    table-layout: auto;
    border: 0;
    background-color: #333542;
    color: #ffffff; }
  .checker__logs .react-bootstrap-table thead {
    background-color: #545769; }
    .checker__logs .react-bootstrap-table thead th {
      font: normal normal 400 13px/22px "Montserrat", sans-serif;
      border: 0; }
      .checker__logs .react-bootstrap-table thead th .logs-datetime__sort .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin: 0 0 1px 4px; }
      .checker__logs .react-bootstrap-table thead th .logs-datetime__sort .dropdown .caret {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #ffffff; }
      .checker__logs .react-bootstrap-table thead th .logs-datetime__sort .dropup .caret {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid black; }
  .checker__logs .react-bootstrap-table tr.log-row-online:hover td {
    background-color: #5f637c;
    cursor: pointer; }
  .checker__logs .react-bootstrap-table tr.log-row-api {
    cursor: not-allowed;
    background-color: #333542 !important; }
    .checker__logs .react-bootstrap-table tr.log-row-api .log-cell-api {
      color: #D7990C !important; }
    .checker__logs .react-bootstrap-table tr.log-row-api .log-cell-id {
      cursor: not-allowed;
      text-decoration: underline;
      color: #787879; }
  .checker__logs .react-bootstrap-table tr:not(.log-row-api) .log-cell-id {
    cursor: help;
    color: #27D7FF;
    text-decoration: underline; }
  .checker__logs .react-bootstrap-table tr td {
    font: normal normal 300 12px/22px "Montserrat", sans-serif;
    border-left: 0;
    border-right: 0;
    border-color: #4D4E4E;
    vertical-align: middle; }
    .checker__logs .react-bootstrap-table tr td .log-cell-result {
      width: 49px;
      height: 14px;
      border-radius: 9px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .checker__logs .react-bootstrap-table tr td .log-cell-result > span {
        font: normal normal 400 11px/1 "Montserrat", sans-serif;
        color: #ffffff; }
      .checker__logs .react-bootstrap-table tr td .log-cell-result--error {
        background-color: #DB0000; }
      .checker__logs .react-bootstrap-table tr td .log-cell-result--valid {
        background-color: #00BA2B; }
      .checker__logs .react-bootstrap-table tr td .log-cell-result--download {
        background-color: #098CB3;
        width: 71px; }
  .checker__logs .react-bootstrap-table tr td {
    padding: 5px 10px; }
  .checker__logs .react-bootstrap-table tr th {
    padding: 6px 10px; }
  .checker__logs .react-bootstrap-table tr td:focus, .checker__logs .react-bootstrap-table tr th:focus {
    outline: none !important; }
  .checker__logs--no-data {
    color: #3BDAFF; }
  .checker__logs--error {
    color: #f03a17;
    text-align: center; }
  .checker__logs--loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 220px; }
    .checker__logs--loading h5 {
      font-size: 16px;
      color: #27D7FF; }

.dot-loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 185px;
  height: 120px; }
  .dot-loader__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    margin: 80px auto; }
    .dot-loader__wrapper h3 {
      color: #77AEBA;
      font-weight: 600; }
  .dot-loader__dots {
    width: 100px;
    height: 23.33333px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-top: 10px; }
  .dot-loader__item {
    will-change: transform;
    height: 23.33333px;
    width: 23.33333px;
    border-radius: 50%;
    background-color: #77AEBA;
    position: absolute;
    animation: grow 0.5s ease-in-out infinite alternate; }
    .dot-loader__item.dot1 {
      left: 0;
      transform-origin: 100% 50%; }
    .dot-loader__item.dot2 {
      left: 50%;
      transform: translateX(-50%) scale(1);
      animation-delay: 0.33s; }
    .dot-loader__item.dot3 {
      right: 0;
      animation-delay: 0.66s; }
  .dot-loader__bracket {
    color: #77AEBA;
    font: normal normal 600 120px/1 "Montserrat", sans-serif; }
    .dot-loader__bracket--left {
      left: 0;
      position: absolute; }
    .dot-loader__bracket--right {
      right: 0;
      position: absolute; }

@keyframes grow {
  to {
    transform: translateX(-50%) scale(0); } }

.CodeMirror-vscrollbar {
  width: 16px !important; }

.CodeMirror-matchingtag {
  color: #75B1DB !important;
  background-color: #25363C !important; }

.custom-codemirror .CodeMirror-hscrollbar,
.custom-codemirror .CodeMirror-scrollbar-filler {
  display: none !important; }

.custom-codemirror.xml-codemirror:not(.comment-txt) .CodeMirror {
  color: #ccffff; }

.custom-codemirror.xml-codemirror.comment-txt .CodeMirror {
  color: #ffffff; }

.custom-codemirror.xml-codemirror.comment-txt .cm-bracket,
.custom-codemirror.xml-codemirror.comment-txt .cm-error {
  color: #ffffff !important; }

.custom-codemirror.xml-codemirror.no-hint .CodeMirror {
  height: 407px !important; }

.custom-codemirror.swift-mt-codemirror .CodeMirror {
  color: #808080; }

.custom-codemirror:not(.comment-txt) > .cm-s-checker-code span.cm-comment {
  color: #6A9955; }

.custom-codemirror.comment-txt > .cm-s-checker-code span.cm-comment {
  color: #ffffff; }

.custom-codemirror:not(.unselectable) .cm-s-checker-code .CodeMirror-selected {
  background: #25363C; }

.custom-codemirror.unselectable .cm-s-checker-code .CodeMirror-selected {
  background: #1E1E1E; }

.CodeMirror {
  margin: 0 0 23px 0;
  display: block;
  width: 100%;
  height: 385px !important;
  padding: 13px 0; }

.cm-s-checker-code.CodeMirror {
  background: #1E1E1E; }

.cm-s-checker-code .CodeMirror-line::selection,
.cm-s-checker-code .CodeMirror-line > span::selection,
.cm-s-checker-code .CodeMirror-line > span > span::selection, .cm-s-checker-code .CodeMirror-line::-moz-selection,
.cm-s-checker-code .CodeMirror-line > span::-moz-selection,
.cm-s-checker-code .CodeMirror-line > span > span::-moz-selection {
  background: #25363C; }

.cm-s-checker-code .CodeMirror-gutters {
  background: #1E1E1E;
  border-right: 0; }

.cm-s-checker-code .CodeMirror-guttermarker {
  color: #ffffff; }
  .cm-s-checker-code .CodeMirror-guttermarker-subtle {
    color: #057594; }

.cm-s-checker-code .CodeMirror-linenumber {
  color: #808080; }

.cm-s-checker-code .CodeMirror-cursor {
  border-left: 1px solid #48FFDA; }

.cm-s-checker-code .CodeMirror-activeline-background {
  background: #01060e; }

.cm-s-checker-code .CodeMirror-matchingbracket {
  color: #8CBCE3 !important;
  border: 1px solid #888888; }

.cm-s-checker-code span.cm-swift-mt {
  color: #4FC1F1; }
  .cm-s-checker-code span.cm-swift-mt-operator {
    color: #808080; }

.cm-s-checker-code span.cm-atom {
  color: #AE81FF; }

.cm-s-checker-code span.cm-number {
  color: #48FFDA; }

.cm-s-checker-code span.cm-property, .cm-s-checker-code span.cm-attribute {
  color: #9CDCFE; }

.cm-s-checker-code span.cm-keyword {
  color: #ff8f40; }

.cm-s-checker-code span.cm-builtin {
  color: #00db6e; }

.cm-s-checker-code span.cm-string {
  color: #C39178; }

.cm-s-checker-code span.cm-variable {
  color: #ccffff; }

.cm-s-checker-code span.cm-variable2 {
  color: #f07178; }

.cm-s-checker-code span.cm-variable3 {
  color: #00bbe6; }

.cm-s-checker-code span.cm-type {
  color: #FDD9AA; }

.cm-s-checker-code span.cm-def {
  color: #ffee99; }

.cm-s-checker-code span.cm-tag {
  color: #4FC1F1; }

.cm-s-checker-code span.cm-bracket {
  color: #808080; }

.cm-s-checker-code span.cm-header {
  color: #EADAFB; }

.cm-s-checker-code span.cm-link {
  color: #00bbe6; }

.cm-s-checker-code span.cm-error {
  color: #ff3333; }

.cm-s-checker-code span.cm-comment.cm-attribute {
  color: #F5C789; }

.cm-s-checker-code span.cm-comment.cm-def {
  color: #4FC1F1; }

.cm-s-checker-code span.cm-comment.cm-tag {
  color: #00bbe6; }

.cm-s-checker-code span.cm-comment.cm-type {
  color: #5998a6; }

.cm-s-checker-code span.cm-meta {
  color: #B5CEA8 !important; }
